import { useSearchParams } from "react-router-dom";
import ChurchOnBoardingForm from "../../components/church-onboarding-form/ChurchOnboardingForm";
import { Suspense } from "react";
import FormWrapperLoading from "../../components/form-wrapper/FormWrapperLoading";

const EditChurchDetails = () => {
  const [searchParams] = useSearchParams();
  const editId = searchParams.get("editId");
  return (
    <Suspense fallback={<FormWrapperLoading />}>
      <ChurchOnBoardingForm editId={editId} />
    </Suspense>
  );
};

export default EditChurchDetails;
