import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import ImagePicker from "../widgets/ImagePicker";
import { cn } from "../../utils/common-utils";

interface ChurchImagePickerProps<T extends FieldValues> {
  form: UseFormReturn<T, any, undefined>;
  logoName: Path<T>;
  coverName: Path<T>;
  className?: string;
  showLabel?: boolean;
}

const ChurchImagePicker = <T extends FieldValues>({
  form,
  logoName,
  coverName,
  className,
  showLabel = true,
}: ChurchImagePickerProps<T>) => {
  return (
    <div className={cn(className)}>
      {showLabel && (
        <label htmlFor={"images"} className="block text-sm font-medium">
          {"Images"}
        </label>
      )}
      <div className="mt-1 bg-transparent p-8 rounded-lg">
        <div className="flex flex-wrap gap-4 gap-x-[140px] justify-center">
          <ImagePicker
            dropRegionClassName="w-[250px] h-[250px]"
            form={form}
            name={logoName}
            label="Church Logo"
            sizeText="250 X 250"
          />
          <ImagePicker
            dropRegionClassName="w-[250px] h-[522px]"
            form={form}
            name={coverName}
            label="Cover Photo"
            sizeText="250 X 522"
          />
        </div>
      </div>
    </div>
  );
};

export default ChurchImagePicker;
