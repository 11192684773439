import { clsx, type ClassValue } from "clsx";
import { jwtDecode } from "jwt-decode";
import { twMerge } from "tailwind-merge";
import { UserDataModel } from "../models/user.model";
import { MapItems } from "../models/church-admin.model";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Set User detail in Local Storage
export const setUserDetailsInLocalStorage = (data: {}) => {
  const mapData = new Map<string, string>(Object.entries(data));
  mapData.forEach((value, key) => {
    if (value) {
      localStorage.setItem(key, value);
    }
  });
};

// Get All User Details From Local Storage
export const getUserDetailFromLocalStorage = (key: string) => {
  return localStorage.getItem(key);
};

// Get Token from Local Storage
export const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

// Clear Local Storage
export const clearLocalStorage = () => {
  localStorage.clear();
};

// Decode Token and Return UserData Objecrt
export const decodeToken = (
  token: string | null | undefined
): UserDataModel | null => {
  if (token && token.trim() !== "" && token !== "undefined") {
    const decodedData = jwtDecode<UserDataModel>(token);

    return {
      token: token,
      email: decodedData.email,
      firstName: decodedData.firstName,
      lastName: decodedData.lastName,
      userRoles: decodedData.userRoles,
      id: decodedData.id,
      churchId: decodedData.churchId,
    };
  }
  return null;
};

export const generateLabelValuePairs = ({
  data,
  labelKey,
  valueKey,
}: MapItems): { label: string; value: string }[] => {
  // Ensure 'data' is an array before using map
  if (!Array.isArray(data)) {
    return []; // Return an empty array if 'data' is not an array
  }

  return data.map((item: any) => ({
    label: item[labelKey] as string,
    value: item[valueKey] as string,
  }));
};