import ChurchAccountFormHeader from "../../components/church-account-form-header/ChurchAccountFormHeader";
import {
  formClassName,
  formContainerClass,
} from "../../constants/common-constants";
import { usePostApi } from "../../services/use-api";
import { cn } from "../../utils/common-utils";
import API_CONSTANTS from "../../constants/api-constant";
import { NotificationFormDataModel } from "../../models/church-admin.model";
import InputField from "../../components/widgets/InputField";
import { useForm } from "react-hook-form";

const NotificationSection = () => {
  const {
    data: notificationDetails,
    postData: sendNotifiation,
    isLoading,
  } = usePostApi<NotificationFormDataModel>({
    path: "",
  });

  const form = useForm<NotificationFormDataModel>({});

  const onSubmit = (data: NotificationFormDataModel) => {
    try {
      sendNotifiation(data, API_CONSTANTS.SEND_NOTIFICATION);
      form.reset({ title: "", notificationBody: "" });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn(formClassName)}
      >
        <ChurchAccountFormHeader
          headerText="Send Notification"
          isLoading={isLoading}
          buttonLabel="Send"
        />
        <div className={formContainerClass}>
          <InputField
            form={form}
            name="title"
            label="Subject"
            validation={{
              required: "Required",
            }}
          />
          <InputField
            form={form}
            name="notificationBody"
            label="Notification"
            textArea={true}
            validation={{
              required: "Required",
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default NotificationSection;
