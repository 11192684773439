import React from "react";

function DeleteAccountInfoPage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4 relative">
      <img
        src="/assets/logo.png"
        alt="App Logo"
        className="absolute top-4 left-4 w-16 h-16"
      />
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h1 className="text-3xl font-bold text-center text-red-600 mb-4">
          Delete Account Flow
        </h1>
        <p className="text-gray-700 mb-6 text-center">
          Follow the steps below to delete your account.
        </p>

        <div className="space-y-4">
          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800">
              1. Open the First 30 app
            </h2>
            <p className="text-gray-600">
              Start by launching the First 30 app on your device.
            </p>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800">
              2. Navigate to the Profile Section
            </h2>
            <p className="text-gray-600">
              Go to the Profile section, which is located on the 5th tab in the
              app navigation bar.
            </p>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800">
              3. Select Delete Account
            </h2>
            <p className="text-gray-600">
              In the Profile section, tap on the "Delete Account" option.
            </p>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800">
              4. Confirm Deletion
            </h2>
            <p className="text-gray-600">
              A confirmation prompt will appear. Select{" "}
              <span className="font-semibold text-red-600">Delete</span> to
              permanently delete your account or{" "}
              <span className="font-semibold text-green-600">Keep</span> if you
              change your mind.
            </p>
          </div>

          <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
            <h2 className="text-xl font-semibold text-gray-800">
              5. Final Step
            </h2>
            <p className="text-gray-600">
              Once confirmed, your account will be permanently deleted, and you
              will lose access to all data associated with it.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccountInfoPage;
