import debounce from "lodash/debounce";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import API_CONSTANTS from "../../constants/api-constant";
import { formClassName } from "../../constants/common-constants";
import {
  emailPattern,
  phoneNumberPattern,
  urlPattern,
  zipCodePattern,
} from "../../constants/regex-pattern";
import { ROUTES } from "../../constants/routes-constants";
import {
  ChruchFormDataModel,
  ChurchOnboardingResDataModel,
  SingleChurchDetailResDataModel,
} from "../../models/platform-admin.model";
import { useGetApi, usePostApi, usePutApi } from "../../services/use-api";
import { cn } from "../../utils/common-utils";
import ChurchImagePicker from "../church-image-picker/ChurchImagePicker";
import FormWrapper from "../form-wrapper/FormWrapper";
import Button from "../widgets/Button";
import InputField from "../widgets/InputField";

interface ChurchOnBoardingFormProps {
  editId?: string | null;
}

const ChurchOnBoardingForm = ({ editId }: ChurchOnBoardingFormProps) => {
  const form = useForm<ChruchFormDataModel>();
  const navigate = useNavigate();
  const { data: churchData, getData: getChurchData } =
    useGetApi<SingleChurchDetailResDataModel>("");
  const {
    data: registerChurchResData,
    postData: registerChurch,
    isLoading,
  } = usePostApi<ChurchOnboardingResDataModel>({
    path: API_CONSTANTS.REGISTER_CHURCH,
  });

  const { data: emailCheckResData, postData: checkEmail } = usePostApi<Boolean>(
    {
      path: API_CONSTANTS.EMAIL_CHECK,
      options: {
        isToaster: false,
      },
    }
  );

  const {
    data: updateChurchResData,
    putData: updateChurchData,
    isLoading: isUpdating,
  } = usePutApi<ChurchOnboardingResDataModel>({
    path: API_CONSTANTS.UPDATE_CHURCH(""),
  });

  useEffect(() => {
    if (registerChurchResData && registerChurchResData.success) {
      navigate(ROUTES.PLATFORM_ADMIN_HOME);
    }
  }, [navigate, registerChurchResData]);

  useEffect(() => {
    if (updateChurchResData && updateChurchResData.success && editId) {
      getChurchData(API_CONSTANTS.GET_CHURCH_DETAIL(editId));
    }
  }, [editId, getChurchData, navigate, updateChurchResData]);

  useEffect(() => {
    if (editId) {
      getChurchData(API_CONSTANTS.GET_CHURCH_DETAIL(editId));
    }
  }, [editId, getChurchData]);

  useEffect(() => {
    if (
      emailCheckResData &&
      emailCheckResData.success &&
      !emailCheckResData.body
    ) {
      form.setError("email", {
        message: emailCheckResData.message ?? "",
      });
    } else {
      form.setError("email", {
        message: "",
      });
    }
  }, [emailCheckResData, form]);

  // On Form Submit
  const onSubmit = (data: ChruchFormDataModel) => {
    const formData = new FormData();
    const churchDataPayload = {
      name: data.churchName.trim(),
      email: data.email.trim(),
      userName: data.userName.trim(),
      phone: data.phoneNumber.trim(),
      contactPersonName: data.contactPersonName.trim(),
      address: data.physicalAddress.trim(),
      city: data.city.trim(),
      state: data.state.trim(),
      zipCode: data.zipCode,
      baptismSignUpLink: data.baptismSignUpLink,
      serveSignUpLink: data.serveSignUpLink,
      communitySignUpLink: data.communitySignUpLink,
      link: data.link,
      notes: data.notes,
      requestPrayerLink: data.requestPrayerLink,
    };
    formData.append("churchData", JSON.stringify(churchDataPayload));
    formData.append("logo", data.churchLogo);
    formData.append("coverPic", data.coverPhoto);
    if (editId) {
      updateChurchData(formData, API_CONSTANTS.UPDATE_CHURCH(editId));
    } else {
      registerChurch(formData);
    }
  };

  // Set Form Data OnLoad
  useEffect(() => {
    if (churchData && churchData.body && churchData.status_code === 200) {
      const body = churchData.body;
      const data: ChruchFormDataModel = {
        churchName: body.name,
        email: body.email,
        userName: body.userName,
        phoneNumber: body.phone,
        contactPersonName: body.contactPersonName,
        physicalAddress: body.address,
        city: body.city,
        state: body.state,
        zipCode: Number(body.zipCode),
        notes: "",
        churchLogo: body.churchLogo,
        coverPhoto: body.coverPhoto,
        baptismSignUpLink: body.baptismSignUpLink,
        communitySignUpLink: body.communitySignUpLink,
        serveSignUpLink: body.serveSignUpLink,
        link: body.link,
        requestPrayerLink: body.requestPrayerLink,
      };
      form.reset(data);
    }
  }, [churchData, form]);

  const editedPage = editId != null;

  return (
    <div>
      <FormWrapper
        showAdminLabel={true}
        className="w-[45%]"
        formHeaderLabel={churchData?.body?.name ?? "Add New Church"}
      >
        <div>
          <form
            className={cn(formClassName)}
            onSubmit={form.handleSubmit(onSubmit)}
          >
            {/* Church Name */}
            <InputField
              form={form}
              readonly={editedPage}
              name="churchName"
              label="Church/Organization Name"
              validation={{
                required: "Required",
              }}
            />
            {/* Email */}
            <InputField
              form={form}
              readonly={editedPage}
              onChange={debounce((event) => {
                const email = event.target.value.trim();
                checkEmail({
                  email: email,
                });
              }, 500)}
              name="email"
              type="email"
              label="Email Address"
              validation={{
                required: "Required",
                pattern: emailPattern,
              }}
            />
            {/* User Name */}
            <InputField
              form={form}
              name="userName"
              labelInfo="Used as login credential"
              readonly={editedPage}
              label="Username"
              validation={{
                required: "Required",
              }}
            />
            {/* Phone Number */}
            <InputField
              form={form}
              name="phoneNumber"
              label="Phone Number"
              validation={{
                required: "Required",
                pattern: phoneNumberPattern,
              }}
            />
            {/* Contact Person Name */}
            <InputField
              form={form}
              name="contactPersonName"
              label="Contact Person Name"
              validation={{
                required: "Required",
              }}
            />
            {/* Physical Address */}
            <InputField
              form={form}
              name="physicalAddress"
              label="Physical Address"
              validation={{
                required: "Required",
              }}
            />
            {/* City, State, Zip Code */}
            <div className="flex space-x-2">
              <div className="w-[50%]">
                <InputField
                  validation={{
                    required: "Required",
                  }}
                  form={form}
                  name="city"
                  label="City"
                />
              </div>
              <div className="w-[10%]">
                <InputField
                  form={form}
                  name="state"
                  label="State"
                  validation={{
                    required: "Required",
                  }}
                />
              </div>
              <div className="w-[40%]">
                <InputField
                  form={form}
                  name="zipCode"
                  label="Zip Code"
                  validation={{
                    required: "Required",
                    pattern: zipCodePattern,
                  }}
                />
              </div>
            </div>
            {/* Notes */}
            {/* <TextAreaField form={form} name="notes" label="Notes" /> */}

            {/* Logo and Cover Picker */}
            <ChurchImagePicker
              form={form}
              logoName="churchLogo"
              coverName="coverPhoto"
            />

            {/* Baptism Sign Up Link */}
            <InputField
              form={form}
              name="baptismSignUpLink"
              label="Baptism Sign Up Link"
              validation={{
                pattern: urlPattern,
              }}
            />
            {/* Community Sign Up Link */}
            <InputField
              form={form}
              name="communitySignUpLink"
              label="Community Sign Up Link"
              validation={{
                pattern: urlPattern,
              }}
            />
            {/* Serve Sign Up Link */}
            <InputField
              form={form}
              name="serveSignUpLink"
              label="Serve Sign Up Link"
              validation={{
                pattern: urlPattern,
              }}
            />
            {/* Link */}
            <InputField
              form={form}
              name="link"
              label="Link"
              validation={{
                pattern: urlPattern,
              }}
            />

            {/* Request Prayer Link */}
            <InputField
              form={form}
              name="requestPrayerLink"
              label="Request Prayer Link"
              validation={{
                pattern: urlPattern,
              }}
            />
            <div className="flex w-full space-x-2 justify-end">
              {/* {editId && (
                <Button className="w-[170px]" label="Cancel Subscription" />
              )} */}
              <Button
                isLoading={isLoading || isUpdating}
                className="w-[170px]"
                label="Apply Changes"
                type="submit"
              />
            </div>
          </form>
        </div>
      </FormWrapper>
    </div>
  );
};

export default ChurchOnBoardingForm;
