import { cn } from "../../utils/common-utils";

interface VerticalBarProps {
  verticalBarClass?: string;
}
const VerticalBar = ({ verticalBarClass }: VerticalBarProps) => {
  return (
    <div className="flex items-center space-x-2 rotate-90">
      <img
        className="-rotate-90"
        src="/assets/svg/down-arrow-icon.svg"
        alt="icon"
      />
      <div
        className={cn(
          "border-dashed border-white w-[79px] h-[0px] border-[1px] bottom-2",
          verticalBarClass
        )}
      ></div>
    </div>
  );
};

export default VerticalBar;
