import { createBrowserRouter } from "react-router-dom";
import {
  CHURCH_ADMIN_BASE_ROUTE,
  PLATFORM_ADMIN_BASE_ROUTE,
  ROUTES,
} from "../constants/routes-constants";
import AddNewChurch from "../pages/platform-admin/AddNewChurch";
import ChurchAccount from "../pages/church-admin/ChurchAccount";
import EditChurchDetails from "../pages/platform-admin/EditChurchDetails";
import Login from "../pages/auth/Login";
import PlatformAdminHome from "../pages/platform-admin/PlatformAdminHome";
import Signup from "../pages/auth/Signup";
import PlatformAdminPrivateRoutes from "./PlatformAdminPrivateRoutes";
import NotFound from "../pages/NotFound";
import AuthRedirect from "./AuthRedirect";
import ChurchAdminPrivateRoutes from "./ChurchAdminPrivateRoutes";
import PaymentSuccess from "../pages/church-admin/PaymentSuccess";
import PaymentFailure from "../pages/church-admin/PaytmentFailure";
import AccessDenied from "../pages/AccessDenied";
import PaymentStatusRoutes from "./PaymentStatusRoutes";
import LandingPage from "../pages/LandingPage";
import TermsAndConditions from "../pages/TermsConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import DeleteUserInfo from "../pages/DeleteUserInfo";
import Demo from "../components/demo/Demo";
import ForgotPassword from "../pages/auth/ForgotPassword";

export const AppRoutes = createBrowserRouter([
  {
    path: ROUTES.LANDING_PAGE,
    element: <LandingPage />,
  },
  {
    path: ROUTES.TERMS_CONDITIONS,
    element: <TermsAndConditions />,
  },
  {
    path: ROUTES.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: ROUTES.LANDING_PAGE,
    element: <LandingPage />,
  },
  {
    path: PLATFORM_ADMIN_BASE_ROUTE,
    element: <PlatformAdminPrivateRoutes />,
    children: [
      {
        path: ROUTES.PLATFORM_ADMIN_HOME,
        element: <PlatformAdminHome />,
      },
      {
        path: ROUTES.ADD_NEW_CHURCH,
        element: <AddNewChurch />,
      },
      {
        path: ROUTES.EDIT_CHURCH,
        element: <EditChurchDetails />,
      },
    ],
  },

  {
    path: CHURCH_ADMIN_BASE_ROUTE,
    element: <ChurchAdminPrivateRoutes />,
    children: [
      {
        path: ROUTES.CHURCH_ADMIN_ACCOUNT(),
        element: <ChurchAccount />,
      },
    ],
  },
  {
    path: ROUTES.LOGIN,
    element: (
      <AuthRedirect>
        <Login />
      </AuthRedirect>
    ),
  },
  {
    path: ROUTES.SIGNUP,
    element: (
      <AuthRedirect>
        <Signup />
      </AuthRedirect>
    ),
  },

  {
    path: CHURCH_ADMIN_BASE_ROUTE,
    element: <PaymentStatusRoutes />,
    children: [
      {
        path: ROUTES.PAYMENT_SUCCESS,
        element: <PaymentSuccess />,
      },
      {
        path: ROUTES.PAYMENT_FAILURE,
        element: <PaymentFailure />,
      },
    ],
  },
  {
    path: ROUTES.ACCESS_DENIED,
    element: <AccessDenied />,
  },
  {
    path: ROUTES.DELETE_USER_INFO,
    element: <DeleteUserInfo />,
  },
  {
    path: ROUTES.DEMO,
    element: <Demo />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
