import { useForm } from "react-hook-form";
import { ChurchAdminUpdateCreadFormDataModel } from "../../models/auth-form.model";
import { usePostApi } from "../../services/use-api";
import API_CONSTANTS from "../../constants/api-constant";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/UserProvider";
import { cn } from "../../utils/common-utils";
import {
  formClassName,
  formContainerClass,
} from "../../constants/common-constants";
import InputField from "../../components/widgets/InputField";
import ChurchAccountFormHeader from "../../components/church-account-form-header/ChurchAccountFormHeader";

const ManageAccountCred = () => {
  const { userData } = useUserContext();
  const form = useForm<ChurchAdminUpdateCreadFormDataModel>();
  const navigate = useNavigate();
  const {
    data: updatePasswordResData,
    postData: updatePasswod,
    isLoading,
  } = usePostApi({
    path: API_CONSTANTS.UPDATE_PASSWORD,
  });

  // On Submit
  const onSubmit = (data: ChurchAdminUpdateCreadFormDataModel) => {
    const payload = {
      password: data.password,
      confirmPassword: data.confirmPassword,
    };
    if (data.password === data.confirmPassword) {
      updatePasswod(payload);
    } else {
      form.setError("confirmPassword", {
        message: "Password Not Matched",
      });
    }
  };

  // Set Password and Confirm Password field empty After Successfully Updated Password
  useEffect(() => {
    if (updatePasswordResData && updatePasswordResData.success) {
      form.setValue("password", "");
      form.setValue("confirmPassword", "");
    }
  }, [form, navigate, updatePasswordResData]);

  // Set UserName from token
  useEffect(() => {
    form.setValue("username", userData?.email ?? "");
  }, [form, userData?.email]);
  return (
    <div>
      <form
        className={cn(formClassName)}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <ChurchAccountFormHeader
          headerText="Username/Password"
          isLoading={isLoading}
        />
        <div className={formContainerClass}>
          {/* UserName */}
          <InputField
            name="username"
            label="Username"
            placeholder="Username"
            form={form}
            validation={{
              required: "Required",
            }}
            readonly={true}
          />
          {/* Password */}
          <InputField
            name="password"
            placeholder="Password"
            label="Password"
            type="password"
            form={form}
            validation={{
              required: "Required",
            }}
          />

          {/* Confirm Password */}
          <InputField
            name="confirmPassword"
            placeholder="Confirm Password"
            label="Confirm Password"
            type="password"
            form={form}
            validation={{
              required: "Required",
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default ManageAccountCred;
