import { useNavigate } from "react-router-dom";
import Button from "../widgets/Button";
import { ROUTES } from "../../constants/routes-constants";
import { ResponseDataModel } from "../../models/response.model";
import { ShieldX } from "lucide-react";

interface ForgotPasswordForbiddenProps {
  data: ResponseDataModel<unknown> | null;
}

const ForgotPasswordForbidden = ({ data }: ForgotPasswordForbiddenProps) => {
  const navigator = useNavigate();
  const goToLogin = () => {
    navigator(ROUTES.LOGIN);
  };
  return (
    <div className="min-h-screen flex flex-col justify-center items-center px-4">
      <div className="max-w-md w-full shadow-lg rounded-lg p-8 text-center">
        <div className="w-16 h-16 rounded-full flex items-center justify-center mx-auto">
          <ShieldX
            color="red"
            width={80}
            height={80}
            className="flex justify-center items-center w-full"
          />
        </div>
        <h2 className="mt-6 text-3xl font-extrabold">Password Update Failed</h2>
        <p className="mt-2 text-sm">{data?.message ?? ""}</p>
        <div className="mt-8 space-y-3">
          <Button className="w-full" label="Go to Login" onClick={goToLogin} />
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForbidden;
