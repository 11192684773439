import { PlanDetailsDataModel } from "../../models/payment-info.model";
import Button from "../widgets/Button";

interface PlanCardProps {
  planDetail: PlanDetailsDataModel;
  onSelect: () => void;
}

const PlanCard = ({ planDetail, onSelect }: PlanCardProps) => {
  return (
    <>
      {
        <div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">
              {planDetail.planTitle}
            </h2>
            <p className="mb-4">{planDetail.description}</p>
            <p>{planDetail.planDescription}</p>
            <div className="flex justify-between items-end mt-10">
              <span className="text-3xl font-bold">{`${planDetail.planCurrency} ${planDetail.pricing}/${planDetail.planRecurring}`}</span>
              <Button
                className="w-[150px] mt-0"
                label="Subscribe Now"
                onClick={onSelect}
              />
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PlanCard;
