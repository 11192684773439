import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/widgets/Button";
import InputField from "../../components/widgets/InputField";
import API_CONSTANTS from "../../constants/api-constant";
import { ROUTES } from "../../constants/routes-constants";
import {
  AuthResponseBodyDataModel,
  LoginFormDataModel,
  RoleEnum,
} from "../../models/auth-form.model";
import { usePostApi } from "../../services/use-api";
import {
  decodeToken,
  setUserDetailsInLocalStorage,
} from "../../utils/common-utils";
import { useUserContext } from "../../context/UserProvider";
import { ChurchAdminAccoundSettingEnum } from "../../models/church-admin.model";

const Login = () => {
  const { setUserData } = useUserContext();
  const form = useForm<LoginFormDataModel>();
  const navigate = useNavigate();
  const {
    data: loginUserResData,
    postData: login,
    isLoading: isLogging,
  } = usePostApi<AuthResponseBodyDataModel>({
    path: API_CONSTANTS.SIGNIN,
  });

  const onSubmit: SubmitHandler<LoginFormDataModel> = (
    data: LoginFormDataModel
  ) => {
    const payload = {
      email: data.username.trim(),
      password: data.password,
    };
    login(payload);
  };

  const goToLandingPage = () => {
    navigate(ROUTES.LANDING_PAGE);
  };

  // On Success Set Token in Local Storage and Decode Token Detail Store in context
  useEffect(() => {
    if (loginUserResData && loginUserResData.success && loginUserResData.body) {
      setUserDetailsInLocalStorage({
        token: loginUserResData.body.token,
      });
      const userData = decodeToken(loginUserResData.body.token);
      setUserData(userData);
      if (userData?.userRoles.includes(RoleEnum.PLATEFORM_ADMIN.toString())) {
        navigate(ROUTES.PLATFORM_ADMIN_HOME);
      } else if (
        userData?.userRoles.includes(RoleEnum.CHURCH_ADMIN.toString())
      ) {
        navigate(
          ROUTES.CHURCH_ADMIN_ACCOUNT(ChurchAdminAccoundSettingEnum.CHURCH_INFO)
        );
      } else {
        navigate(ROUTES.PLATFORM_ADMIN_HOME);
      }
    }
  }, [loginUserResData, navigate, setUserData]);

  return (
    <div
      //className="relative overflow-hidden rounded-lg bg-cover bg-no-repeat p-12 text-center h-[100vh] bg-[center_40%]"
      className="relative overflow-hidden rounded-lg bg-cover bg-no-repeat p-12 text-center h-[100vh]"
      style={{
        //backgroundImage: "url(/assets/login-bg.jpeg)",
        backgroundImage: "url(/assets/landing-image.png)",
      }}
    >
      <div
        onClick={goToLandingPage}
        className="absolute top-[10px] left-[10px] bg-black w-fit rounded-[40px]"
      >
        <img height={54} width={54} src="/assets/logo.png" alt="logo" />
      </div>
      <div className="absolute flex right-[17px] top-[17px] bottom-[17px] w-[30%] rounded-[30px] justify-center bg-background p-8 overflow-y-auto">
        <div className="w-full max-w-md space-y-8">
          <div className="flex justify-center w-full mb-8 mt-8 left-8">
            <img height={82} width={82} src="/assets/logo.png" alt="logo" />
          </div>
          <h2 className="text-[48px] font-light text-white text-center mb-8">
            Login
          </h2>
          <div className="">
            <form
              className="space-y-8 mb-2"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <InputField<LoginFormDataModel>
                name="username"
                form={form}
                label="Username"
                placeholder="Username"
                validation={{
                  required: "Required",
                }}
              />
              <div className="space-y-1">
                <InputField<LoginFormDataModel>
                  name="password"
                  type="password"
                  form={form}
                  label="Password"
                  placeholder="Password"
                  validation={{
                    required: "Required",
                  }}
                />
                <Link
                  to={ROUTES.FORGOT_PASSWORD}
                  className="flex justify-end underline cursor-pointer hover:text-gray-400"
                >
                  Forgot Password?
                </Link>
              </div>
              <Button
                className="w-full"
                isLoading={isLogging}
                type="submit"
                label="Log In"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
