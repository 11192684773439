import { useEffect } from "react";
import { useForm } from "react-hook-form";
import CreadUpdateForm from "../../components/cread-update-form/CreadUpdateForm";
import {
  AuthResponseBodyDataModel,
  ChurchAdminUpdateCreadFormDataModel,
  RoleEnum,
} from "../../models/auth-form.model";
import { useNavigate, useSearchParams } from "react-router-dom";
import { usePostApi } from "../../services/use-api";
import API_CONSTANTS from "../../constants/api-constant";
import {
  decodeToken,
  setUserDetailsInLocalStorage,
} from "../../utils/common-utils";
import { useUserContext } from "../../context/UserProvider";
import { ROUTES } from "../../constants/routes-constants";
import { ChurchAdminAccoundSettingEnum } from "../../models/church-admin.model";

const Signup = () => {
  const { setUserData } = useUserContext();
  const navigate = useNavigate();
  const form = useForm<ChurchAdminUpdateCreadFormDataModel>();
  const [searchParams] = useSearchParams();
  const {
    data: signUpResData,
    postData: signUp,
    isLoading,
  } = usePostApi<AuthResponseBodyDataModel>({
    path: API_CONSTANTS.CHURCH_ADMIN_SIGNUP,
  });

  const confirmPasswordWatch = form.watch("confirmPassword");
  const passwordWatch = form.watch("password");

  const onSubmit = (data: ChurchAdminUpdateCreadFormDataModel) => {
    const payload = {
      username: data.username,
      password: data.password,
      confirmPassword: data.confirmPassword,
    };
    if (data.password === data.confirmPassword) {
      signUp(payload);
    } else {
      form.setError("confirmPassword", {
        message: "Password Not Matched",
      });
    }
  };
  useEffect(() => {
    const password = form.getValues("password");
    if (password.length !== 0 && confirmPasswordWatch !== password) {
      form.setError("confirmPassword", {
        message: "Password Not Matched",
      });
    } else {
      form.setError("confirmPassword", {
        message: "",
      });
    }
  }, [confirmPasswordWatch, form]);

  useEffect(() => {
    if (passwordWatch?.length === 0) {
      form.setError("confirmPassword", {
        message: "",
      });
    }
  }, [form, passwordWatch?.length]);

  useEffect(() => {
    form.setValue("username", searchParams.get("username") ?? "");
  }, [form, searchParams]);

  useEffect(() => {
    if (signUpResData && signUpResData.success && signUpResData.body) {
      setUserDetailsInLocalStorage({
        token: signUpResData.body.token,
      });
      const userData = decodeToken(signUpResData.body.token);
      setUserData(userData);
      if (userData?.userRoles.includes(RoleEnum.PLATEFORM_ADMIN.toString())) {
        navigate(ROUTES.PLATFORM_ADMIN_HOME);
      } else if (
        userData?.userRoles.includes(RoleEnum.CHURCH_ADMIN.toString())
      ) {
        navigate(
          ROUTES.CHURCH_ADMIN_ACCOUNT(ChurchAdminAccoundSettingEnum.CHURCH_INFO)
        );
      } else {
        navigate(ROUTES.PLATFORM_ADMIN_HOME);
      }
    }
  }, [navigate, setUserData, signUpResData]);

  return (
    <div
      className="relative overflow-hidden rounded-lg bg-cover bg-no-repeat p-12 text-center h-[100vh]"
      style={{
        backgroundImage: "url(/assets/landing-image.png)",
      }}
    >
      <div className="absolute flex right-[17px] top-[17px] bottom-[17px] w-[556px] rounded-[30px] justify-center bg-background p-8 overflow-y-auto">
        <div className="w-full max-w-md space-y-8">
          <div className="flex justify-center w-full mb-8 mt-8 left-8">
            <img
              height={82}
              width={82}
              src="/assets/landing-image.png"
              alt="logo"
            />
          </div>
          <h2 className="text-[48px] font-light text-white text-center mb-8">
            Sign Up
          </h2>
          <CreadUpdateForm
            buttonLabel="Sign Up"
            formHeaderLabel="Sign Up"
            form={form}
            buttonClassName="w-full"
            onSubmit={onSubmit}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
