import React from "react";
import { Home } from "lucide-react";

const NotFound = () => {
  return (
    <div className="text-white min-h-screen flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-6xl font-bold mb-4">404</h1>
        <h2 className="text-3xl font-semibold mb-6">Page Not Found</h2>
        <p className="text-xl mb-8">
          Oops! The page you're looking for doesn't exist.
        </p>
        <a
          href="/"
          className="inline-flex items-center px-6 py-3 bg-transparent text-white rounded-md hover:opacity-45 border-white border-[1px] border-opacity-25 transition-colors"
        >
          <Home className="mr-2" size={20} />
          Back to Dashboard
        </a>
      </div>
    </div>
  );
};

export default NotFound;
