import { Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../constants/routes-constants";
import { RoleEnum } from "../models/auth-form.model";
import { decodeToken, getTokenFromLocalStorage } from "../utils/common-utils";
import NoAccessPage from "../components/no-access/NoAccessPage";
import AppBar from "../components/app-bar/AppBar";
import RestrictSmallDeviceDialog from "../components/restrict-small-device-dialog/RestrictSmallDeviceDialog";
const ChurchAdminPrivateRoutes = () => {
  const userData = decodeToken(getTokenFromLocalStorage());

  return (
    <>
      <div className="hidden lg:block">
        {userData &&
        userData.token &&
        userData.userRoles.includes(RoleEnum.CHURCH_ADMIN.toString()) ? (
          <>
            <AppBar />
            <Outlet />
          </>
        ) : userData &&
          userData.token &&
          !userData.userRoles.includes(RoleEnum.CHURCH_ADMIN.toString()) ? (
          <NoAccessPage />
        ) : (
          <Navigate to={ROUTES.LOGIN} replace={true} />
        )}
      </div>
      <div className="lg:hidden block">
        <RestrictSmallDeviceDialog />
      </div>
    </>
  );
};

export default ChurchAdminPrivateRoutes;
