import { useForm } from "react-hook-form";
import { ChurchInfoFormDataModel } from "../../models/church-admin.model";
import InputField from "../../components/widgets/InputField";
import { cn } from "../../utils/common-utils";
import {
  formClassName,
  formContainerClass,
} from "../../constants/common-constants";
import {
  emailPattern,
  phoneNumberPattern,
  urlPattern,
  zipCodePattern,
} from "../../constants/regex-pattern";
import API_CONSTANTS from "../../constants/api-constant";
import { useGetApi, usePutApi } from "../../services/use-api";
import { useEffect, useMemo } from "react";
import { SingleChurchDetailResDataModel } from "../../models/platform-admin.model";
import { useUserContext } from "../../context/UserProvider";
import ChurchAccountFormHeader from "../../components/church-account-form-header/ChurchAccountFormHeader";

const ChurchInfo = () => {
  const { userData } = useUserContext();
  const form = useForm<ChurchInfoFormDataModel>();
  const {
    data: updateChuchInfoResData,
    putData: updateChurchInfo,
    isLoading: isChurchInfoUpdating,
  } = usePutApi({
    path: "",
  });
  const { data: churchInfoResData, getData: getChurchInfo } =
    useGetApi<SingleChurchDetailResDataModel>("");

  const editId = useMemo(() => userData?.churchId ?? "", [userData]);

  const onSubmit = (data: ChurchInfoFormDataModel) => {
    const formData = new FormData();
    const payload = {
      name: data.churchName,
      email: data.email,
      phone: data.phoneNumber,
      contactPersonName: data.contactPersonName,
      address: data.physicalAddress,
      city: data.city,
      state: data.state,
      zipCode: data.zipCode,
      requestPrayerLink: data.requestPrayerLink,
    };
    formData.append("churchData", JSON.stringify(payload));
    updateChurchInfo(formData, API_CONSTANTS.UPDATE_CHURCH(editId));
  };

  // Get Church Details
  useEffect(() => {
    if (editId) {
      getChurchInfo(API_CONSTANTS.GET_CHURCH_DETAIL(editId));
    }
  }, [editId, getChurchInfo]);

  // Handle Change in Church Detail Res Data
  useEffect(() => {
    if (
      churchInfoResData &&
      churchInfoResData.success &&
      churchInfoResData.body
    ) {
      const content = churchInfoResData.body;
      const data: ChurchInfoFormDataModel = {
        churchName: content.name,
        city: content.city,
        contactPersonName: content.contactPersonName,
        email: content.email,
        phoneNumber: content.phone,
        physicalAddress: content.address,
        state: content.state,
        zipCode: content.zipCode,
        requestPrayerLink: content.requestPrayerLink,
      };
      form.reset(data);
    }
  }, [churchInfoResData, form]);

  // Get Church Info After Updating Details
  useEffect(() => {
    if (updateChuchInfoResData && updateChuchInfoResData.success) {
      getChurchInfo(API_CONSTANTS.GET_CHURCH_DETAIL(editId));
    }
  }, [editId, getChurchInfo, updateChuchInfoResData]);

  return (
    <div>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={cn(formClassName)}
      >
        <ChurchAccountFormHeader
          headerText="Church Info"
          isLoading={isChurchInfoUpdating}
        />
        <div className={formContainerClass}>
          {/* Church/Organization Name */}
          <InputField
            form={form}
            name="churchName"
            label="Church/Organization Name"
            validation={{
              required: "Required",
            }}
          />

          {/* Email Address */}
          <InputField
            form={form}
            name="email"
            readonly={true}
            label="Email Address"
            validation={{
              required: "Required",
              pattern: emailPattern,
            }}
          />

          {/* Phone Number */}
          <InputField
            form={form}
            name="phoneNumber"
            label="Phone Number"
            validation={{
              required: "Required",
              pattern: phoneNumberPattern,
            }}
          />

          {/* Contact Person Name */}
          <InputField
            form={form}
            name="contactPersonName"
            label="Contact Person Name"
            validation={{
              required: "Required",
            }}
          />

          {/* Physical Address */}
          <InputField
            form={form}
            name="physicalAddress"
            label="Physical Address"
            validation={{
              required: "Required",
            }}
          />

          {/* City, State, Zip Code */}
          <div className="flex space-x-2">
            <div className="w-[50%]">
              <InputField
                form={form}
                name="city"
                label="City"
                validation={{
                  required: "Required",
                }}
              />
            </div>
            <div className="w-[10%]">
              <InputField
                form={form}
                name="state"
                label="State"
                validation={{
                  required: "Required",
                }}
              />
            </div>
            <div className="w-[40%]">
              <InputField
                form={form}
                name="zipCode"
                label="Zip Code"
                validation={{
                  required: "Required",
                  pattern: zipCodePattern,
                }}
              />
            </div>
          </div>

          {/* Request Prayer Link */}
          {/* Link */}
          <InputField
            form={form}
            name="requestPrayerLink"
            label="Request Prayer Link"
            validation={{
              pattern: urlPattern,
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default ChurchInfo;
