import { Outlet, useNavigate } from "react-router-dom";
import NoAccessPage from "../components/no-access/NoAccessPage";
import { ROUTES } from "../constants/routes-constants";
import { RoleEnum } from "../models/auth-form.model";
import { decodeToken, getTokenFromLocalStorage } from "../utils/common-utils";
import { ChurchAdminAccoundSettingEnum } from "../models/church-admin.model";

const PaymentStatusRoutes = () => {
  const userData = decodeToken(getTokenFromLocalStorage());
  const isPaymentInProcess = sessionStorage.getItem("isPaymentInProcess");
  const navigate = useNavigate();
  const goToDashboard = () => {
    navigate(
      ROUTES.CHURCH_ADMIN_ACCOUNT(ChurchAdminAccoundSettingEnum.CHURCH_INFO)
    );
  };

  return userData &&
    userData.token &&
    userData.userRoles.includes(RoleEnum.CHURCH_ADMIN.toString()) &&
    isPaymentInProcess ? (
    <>
      <Outlet />
    </>
  ) : (
    <NoAccessPage btnLabel="Go to Dashboard" onClick={goToDashboard} />
  );
};

export default PaymentStatusRoutes;
