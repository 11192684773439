import React, { ChangeEvent, useEffect, useState } from "react";
import {
  FieldValues,
  Path,
  RegisterOptions,
  UseFormReturn,
} from "react-hook-form";
import { cn } from "../../utils/common-utils";
import { v4 as uuidv4 } from "uuid";
import { Trash2Icon } from "lucide-react";

interface ImagePickerProps<T extends FieldValues> {
  form: UseFormReturn<T, any, undefined>;
  name: Path<T>;
  label?: string;
  validation?: RegisterOptions<T, Path<T>>;
  readonly?: boolean;
  dropRegionClassName?: string;
  className?: string;
  sizeText?: string;
}

const ImagePicker = <T extends FieldValues>({
  form,
  name,
  label,
  validation,
  readonly,
  dropRegionClassName,
  sizeText,
  className,
}: ImagePickerProps<T>) => {
  const [logo, setLogo] = useState<string | ArrayBuffer | null | undefined>(
    null
  );
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogo(e.target?.result);
        form.setValue(name, file as any);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogo(e.target?.result);
        form.setValue(name, file as any);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const imageWatch = form.watch(name);

  useEffect(() => {
    if (typeof imageWatch === "string") {
      setLogo(
        imageWatch.toString().trim() === ""
          ? null
          : imageWatch + `?uuid=${uuidv4()}`
      );
    }
  }, [imageWatch]);

  return (
    <div>
      <p className="text-white flex justify-start mb-2">{label}</p>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        className={cn("flex flex-col items-center relative", className)}
      >
        {logo && false ? (
          <div className={cn(dropRegionClassName)}>
            <div
              onClick={(e) => {
                setLogo(null);
                form.setValue(name, null as any);
                e.stopPropagation();
              }}
              className="absolute cursor-pointer bg-[#24282D] top-[35%] left-[35%] p-5 rounded-[90px] text-white "
            >
              <Trash2Icon className="text-white" />
            </div>
            <img
              src={logo as string}
              alt={label}
              className="w-full h-full object-cover rounded-[30px]"
            />
          </div>
        ) : (
          <label className="cursor-pointer">
            <div
              className={cn(
                `bg-[#FFFFFF0A] rounded-[30px] flex items-center justify-center w-[250px] h-[250px]`,
                dropRegionClassName
              )}
            >
              {logo ? (
                <div className={cn(dropRegionClassName)}>
                  <img
                    src={logo as string}
                    alt={label}
                    className="w-full h-full object-cover rounded-[30px]"
                  />
                </div>
              ) : (
                <div className="space-y-2">
                  <span className="flex text-white justify-center items-center bg-[#24282D] w-[142px] h-[47px] rounded-[900px]">
                    Upload
                  </span>
                  <div className="flex justify-center text-xs">{sizeText}</div>
                </div>
              )}
            </div>
            <input
              type="file"
              className="hidden absolute top-[50%]"
              onChange={(e) => {
                handleFileChange(e);
                e.stopPropagation();
              }}
              accept="image/*"
            />
          </label>
        )}
      </div>
    </div>
  );
};

export default ImagePicker;
