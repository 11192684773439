export const PLATFORM_ADMIN_BASE_ROUTE = "/platform-admin";
export const CHURCH_ADMIN_BASE_ROUTE = "/church-admin";

export const ROUTES = {
  PLATFORM_ADMIN_HOME: `${PLATFORM_ADMIN_BASE_ROUTE}`,
  LOGIN: `/login`,
  SIGNUP: `${CHURCH_ADMIN_BASE_ROUTE}/signup`,
  ADD_NEW_CHURCH: `${PLATFORM_ADMIN_BASE_ROUTE}/add-new-church`,
  EDIT_CHURCH: `${PLATFORM_ADMIN_BASE_ROUTE}/edit-church-details`,
  CHURCH_ADMIN: CHURCH_ADMIN_BASE_ROUTE,
  CHURCH_ADMIN_ACCOUNT: (tab?: number) => {
    if (tab) {
      return `${CHURCH_ADMIN_BASE_ROUTE}/account?tab=${tab}`;
    }
    return `${CHURCH_ADMIN_BASE_ROUTE}/account`;
  },

  PAYMENT_SUCCESS: `${CHURCH_ADMIN_BASE_ROUTE}/payment-success`,
  PAYMENT_FAILURE: `${CHURCH_ADMIN_BASE_ROUTE}/payment-failure`,
  FORGOT_PASSWORD: "/forgot-password",
  ACCESS_DENIED: "/access-denied",
  LANDING_PAGE: "/",
  TERMS_CONDITIONS: "/termsandconditions",
  PRIVACY_POLICY: "/privacy-policy",
  DELETE_USER_INFO: "/delete-user-info",
  DEMO: "/demo",
  INSTAGRAM_PAGE:
    "https://www.instagram.com/yourfirst30days?igsh=ODd0MWNtdnVqbXht",
};
