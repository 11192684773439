const FormWrapperLoading = () => {
  return (
    <div className="flex text-white p-8 h-screen items-center">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <div>
            <div className="h-10 w-64 bg-gray-600 rounded animate-pulse"></div>
          </div>
          <div className="h-10 w-40 bg-gray-600 rounded animate-pulse"></div>
        </div>

        <div className="h-[60vh] w-[60vw] bg-gray-600 rounded animate-pulse"></div>
        <div className="mt-8 h-10 w-28 bg-gray-600 rounded animate-pulse"></div>
      </div>
    </div>
  );
};
export default FormWrapperLoading;
