import axios, { AxiosInstance } from "axios";
import { ENV_VARIABLES } from "./config";
import { getTokenFromLocalStorage } from "../utils/common-utils";

const AxiosClient: AxiosInstance = axios.create({
  baseURL: ENV_VARIABLES.API_BASE,
});

// Add request interceptor
AxiosClient.interceptors.request.use(
  (config: any) => {
    const authorizationToken = getTokenFromLocalStorage();

    if (authorizationToken) {
      config.headers!["Authorization"] = `Bearer ${authorizationToken}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// Add response interceptor
AxiosClient.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

export default AxiosClient;
