import { useForm } from "react-hook-form";
import { ChurchImagesFormDataModel } from "../../models/church-admin.model";
import ChurchImagePicker from "../../components/church-image-picker/ChurchImagePicker";
import { useGetApi, usePutApi } from "../../services/use-api";
import { useEffect, useMemo } from "react";
import API_CONSTANTS from "../../constants/api-constant";
import { SingleChurchDetailResDataModel } from "../../models/platform-admin.model";
import { useUserContext } from "../../context/UserProvider";
import ChurchAccountFormHeader from "../../components/church-account-form-header/ChurchAccountFormHeader";
import {
  formClassName,
  formContainerClass,
} from "../../constants/common-constants";

const ChurchImages = () => {
  const { userData } = useUserContext();
  const form = useForm<ChurchImagesFormDataModel>();
  const {
    data: updateChurchImagesResData,
    putData: updateChurchImages,
    isLoading,
  } = usePutApi({
    path: "",
  });
  const { data: churchImages, getData: getChurchImages } =
    useGetApi<SingleChurchDetailResDataModel>("");

  const editId = useMemo(() => userData?.churchId ?? "", [userData]);

  const onSubmit = (data: ChurchImagesFormDataModel) => {
    const formData = new FormData();
    const churchResData = churchImages?.body;
    if (churchResData) {
      const churchDataPayload = {
        name: churchResData.name,
        email: churchResData.email,
        userName: churchResData.userName,
        phone: churchResData.phone,
        contactPersonName: churchResData.contactPersonName,
        address: churchResData.address,
        city: churchResData.city,
        state: churchResData.state,
        zipCode: churchResData.zipCode,
        baptismSignUpLink: churchResData.baptismSignUpLink,
        communitySignUpLink: churchResData.communitySignUpLink,
        serveSignUpLink: churchResData.serveSignUpLink,
        link: churchResData.link,
        requestPrayerLink: churchResData.requestPrayerLink,
      };
      formData.append("churchData", JSON.stringify(churchDataPayload));
    }

    formData.append("logo", data.churchLogo);
    formData.append("coverPic", data.coverPhoto);
    updateChurchImages(formData, API_CONSTANTS.UPDATE_CHURCH(editId));
  };

  // Get Church Images
  useEffect(() => {
    if (editId) {
      getChurchImages(API_CONSTANTS.GET_CHURCH_DETAIL(editId));
    }
  }, [editId, getChurchImages]);

  // Set Church Images onLoad
  useEffect(() => {
    if (churchImages && churchImages.success && churchImages.body) {
      const multimedia = churchImages.body;
      const data: ChurchImagesFormDataModel = {
        churchLogo: multimedia.churchLogo,
        coverPhoto: multimedia.coverPhoto,
      };
      form.reset(data);
    }
  }, [churchImages, form]);

  useEffect(() => {
    if (updateChurchImagesResData && updateChurchImagesResData.success) {
      getChurchImages(API_CONSTANTS.GET_CHURCH_DETAIL(editId));
    }
  }, [editId, getChurchImages, updateChurchImagesResData]);

  return (
    <div>
      <form className={formClassName} onSubmit={form.handleSubmit(onSubmit)}>
        <ChurchAccountFormHeader
          headerText="Church Images"
          isLoading={isLoading}
        />
        <ChurchImagePicker
          form={form}
          showLabel={false}
          className={formContainerClass}
          logoName="churchLogo"
          coverName="coverPhoto"
        />
      </form>
    </div>
  );
};
export default ChurchImages;
