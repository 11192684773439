import { Bounce } from "react-toastify";
import { ToastOptions } from "react-toastify/dist/types";

export const TOASTER_CONFIG: ToastOptions = {
  position: "top-right",
  hideProgressBar: false,
  pauseOnHover: true,
  theme: "colored",
  transition: Bounce,
  closeOnClick: true,
  draggable: true,
};

export const formClassName = "space-y-[36px] mt-2";
export const formContainerClass =
  "p-8 pt-12 rounded-[30px] border-[#1D2025] border-[1px] space-y-[36px]";
