import {
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserContextDataModel, UserDataModel } from "../models/user.model";
import { decodeToken, getTokenFromLocalStorage } from "../utils/common-utils";

const UserContext = createContext<UserContextDataModel>({
  userData: null,
  setUserData: (value: SetStateAction<UserDataModel | null>) => {},
});

export const useUserContext = () => {
  return useContext(UserContext);
};
const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserDataModel | null>(null);

  useEffect(() => {
    const token = getTokenFromLocalStorage();
    const decodedData = decodeToken(token);
    if (decodedData) {
      setUser(decodedData);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        userData: user,
        setUserData: setUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
