import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes-constants";
import { ChurchAdminAccoundSettingEnum } from "../../models/church-admin.model";

const PaymentFailure = () => {
  const navigate = useNavigate();
  const goToDashboard = () => {
    sessionStorage.removeItem("isPaymentInProcess");
    navigate(
      ROUTES.CHURCH_ADMIN_ACCOUNT(ChurchAdminAccoundSettingEnum.CHURCH_INFO),
      {
        replace: true,
      }
    );
  };
  return (
    <div className="min-h-screen flex flex-col justify-center items-center px-4">
      <div className="max-w-md w-full shadow-lg rounded-lg p-8 text-center">
        <div className="w-16 h-16 bg-red-500 rounded-full flex items-center justify-center mx-auto">
          <svg
            className="w-8 h-8 text-red-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="text-white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold">Payment Failed</h2>
        <p className="mt-2 text-sm">
          We're sorry, but your payment could not be processed. Please try again
          or contact support for assistance.
        </p>
        <div className="mt-8 space-y-3">
          <button
            onClick={goToDashboard}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Try Again
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailure;
