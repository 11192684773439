export interface LoginFormDataModel {
  username: string;
  password: string;
}

export interface ChurchAdminUpdateCreadFormDataModel {
  username: string;
  password: string;
  confirmPassword: string;
}

export interface AuthResponseBodyDataModel {
  token: string;
  isSubscriber: boolean;
}

export enum RoleEnum {
  AUDIENCE = "ROLE_AUDIENCE",
  PLATEFORM_ADMIN = "ROLE_PLATEFORM_ADMIN",
  CHURCH_ADMIN = "ROLE_CHURCH_ADMIN",
}

export interface EmailVerificationFormDataModel {
  email: string;
}

export interface ForgotPasswordFormDataModel {
  email: string;
  newPassword: string;
  confirmNewPassword: string;
  code: string;
}
