import { CheckCircle } from "lucide-react";
import Button from "../widgets/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes-constants";

const RequestDemoSuccess = () => {
  const navigator = useNavigate();

  const goToLandingPage = () => {
    navigator(ROUTES.LANDING_PAGE);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <div className="max-w-md w-full rounded-lg shadow-lg p-8 text-center">
        <div className="mb-6">
          <CheckCircle color="green" className="mx-auto h-16 w-16" />
        </div>

        <h1 className="text-2xl font-bold mb-4">
          Thank You for Your Interest!
        </h1>

        <p className="mb-6">
          Your demo request has been successfully submitted. One of our team
          members will contact you within 24 hours to schedule your personalized
          demo.
        </p>

        <div className="border-t border-gray-200 pt-6 mt-6">
          <h2 className="text-lg font-semibold  mb-3">What's Next?</h2>
          <ul className="text-left text-gray-600 space-y-3 mb-6">
            <li className="flex items-start">
              <div className="flex-shrink-0 h-5 w-5 relative top-0.5">
                <CheckCircle color="green" className="h-5 w-5" />
              </div>
              <span className="ml-3">Check your email for confirmation</span>
            </li>
            <li className="flex items-start">
              <div className="flex-shrink-0 h-5 w-5 relative top-0.5">
                <CheckCircle color="green" className="h-5 w-5" />
              </div>
              <span className="ml-3">Prepare your questions for the demo</span>
            </li>
            <li className="flex items-start">
              <div className="flex-shrink-0 h-5 w-5 relative top-0.5">
                <CheckCircle color="green" className="h-5 w-5" />
              </div>
              <span className="ml-3">Our team will reach out to schedule</span>
            </li>
          </ul>
        </div>

        <div className="space-y-4">
          <Button onClick={goToLandingPage} className="w-full">
            Return to Homepage
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RequestDemoSuccess;
