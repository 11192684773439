import { useEffect, useState } from "react";
import API_CONSTANTS from "../constants/api-constant";
import { CheckSubscriptionResDataModel } from "../models/payment-info.model";
import { useGetApi } from "./use-api";

export const useGetPaymentDetails = () => {
  const [paymentDetails, setPaymentDetails] =
    useState<CheckSubscriptionResDataModel | null>(null);
  const {
    data: checkSubscribeResData,
    getData: checkSubscribe,
    isLoading: isPaymentDetailsLoading,
    error: checkSubscribeError,
  } = useGetApi<CheckSubscriptionResDataModel>(
    API_CONSTANTS.CHECK_SUBSCRIPTION
  );

  // Check Subscription and Set Context
  useEffect(() => {
    if (
      checkSubscribeResData &&
      checkSubscribeResData.success &&
      checkSubscribeResData.body
    ) {
      setPaymentDetails({
        isSubscribed: checkSubscribeResData.body?.isSubscribed ?? false,
        isExpired: checkSubscribeResData.body?.isExpired ?? false,
        expirationDate: checkSubscribeResData.body?.expirationDate ?? "",
        subscriptionPriceId:
          checkSubscribeResData.body.subscriptionPriceId ?? "",
      });
    }
  }, [checkSubscribeResData]);
  return {
    checkSubscribeResData,
    checkSubscribe,
    isPaymentDetailsLoading,
    paymentDetails,
    checkSubscribeError,
  };
};
