import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/UserProvider";
import Button from "../widgets/Button";
import { clearLocalStorage } from "../../utils/common-utils";
import { ROUTES } from "../../constants/routes-constants";
import AppDialog from "../app-dialog/AppDialog";
import { useState } from "react";

const AppBar = () => {
  const { userData, setUserData } = useUserContext();
  const navigate = useNavigate();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const openConfirmationDialog = () => {
    setIsConfirmationDialogOpen(true);
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  //   Logout Handler
  const logout = () => {
    clearLocalStorage();
    setUserData(null);
    navigate(ROUTES.LOGIN, {
      replace: true,
    });
  };
  return (
    <>
      <div className="flex justify-between p-4">
        <img className="w-[54px] h-[54px]" src="/assets/logo.png" alt="logo" />
        {userData && (
          <Button
            className="m-1 w-[80px] h-[35px] text-black"
            onClick={openConfirmationDialog}
          >
            Logout
          </Button>
        )}
      </div>
      <AppDialog
        title="Are you sure you want to logout"
        isOpen={isConfirmationDialogOpen}
        onConfirm={logout}
        onClose={closeConfirmationDialog}
        confirmTitle="Yes"
        closeTitle="No"
        isLoading={false}
        description=""
      />
    </>
  );
};

export default AppBar;
