export const headingTestStyle = {
  fontFamily: "'Fjalla One', sans-serif",
  wordSpacing: -5,
  lineHeight: "84px",
  fontWeight: 900,
  fontStyle: "italic",
  fontSize: "90px",
};

export const numberHeadingTextClass =
  "absolute -top-[70%] -left-10 text-[150px] font-thin text-[#FFFFFF17]";
