import React from "react";
import Button from "../widgets/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes-constants";

const PasswordUpdatedPage = () => {
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center px-4">
      <div className="max-w-md w-full shadow-lg rounded-lg p-8 text-center">
        <div className="w-16 h-16 bg-primary rounded-full flex items-center justify-center mx-auto">
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold">
          Password Updated Successfully
        </h2>
        <p className="mt-2 text-sm">
          Your password has been successfully changed. You can now use your new
          password to log in.
        </p>
        <div className="mt-8 space-y-3">
          <Button className="w-full" label="Go to Login" onClick={goToLogin} />
        </div>
      </div>
    </div>
  );
};

export default PasswordUpdatedPage;
