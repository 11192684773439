import { headingTestStyle } from "../../constants/landing-page-constants";

const DownloadApp = () => {
  return (
    <section className="bg-[#1B1F24] h-[946px] text-center flex justify-center items-center">
      <div className="container mx-auto px-6">
        <div className="mx-auto space-y-12">
          <div className="flex justify-center items-center mb-10 ">
            <img
              height={78}
              width={78}
              alt="Logo"
              src="/assets/secondary-logo.png"
            />
          </div>
          <div className="space-y-2">
            <h1
              className="text-5xl font-bold text-primary"
              style={{
                ...headingTestStyle,
                fontSize: undefined,
                lineHeight: undefined,
              }}
            >
              JUST ACCEPTED JESUS?
            </h1>
            <h1
              className="text-5xl font-bold mb-12"
              style={{
                ...headingTestStyle,
                fontSize: undefined,
                lineHeight: undefined,
              }}
            >
              DOWNLOAD THE APP TO START YOUR JOURNEY
            </h1>
          </div>
          <div className="flex justify-center gap-24">
            <div className="space-y-4">
              <div className="w-32 h-32 bg-white rounded-[30px] p-3">
                <img src="/assets/appstore-qr.png" alt="QR Code" />
              </div>
              <div className="text-sm">Apple</div>
            </div>
            <div className="space-y-4">
              <div className="w-32 h-32 bg-white rounded-[30px] p-3">
                <img src="/assets/qr-code.png" alt="QR Code" />
              </div>
              <div className="text-sm">Google Play</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default DownloadApp;
