import { useNavigate } from "react-router-dom";
import { headingTestStyle } from "../../constants/landing-page-constants";
import BlurEffect from "../blur-effect/BlurEffect";
import Button from "../widgets/Button";
import { ROUTES } from "../../constants/routes-constants";

interface HeroSectionProps {
  scrollToDownloadAppSection: () => void;
}

const HeroSection = ({ scrollToDownloadAppSection }: HeroSectionProps) => {
  const navigator = useNavigate();

  const goToDemoPage = () => {
    navigator(ROUTES.DEMO);
  };

  return (
    <section className="relative mx-auto px-6 pt-12 pb-0 flex flex-col-reverse lg:flex-row items-center justify-around ">
      <BlurEffect />
      <div className="lg:w-1/3 space-y-6 mb-10">
        <label className="text-primary font-bold">Your First 30 Days</label>
        <h1
          className="text-5xl lg:text-6xl font-bold text-white leading-tight tracking-tight"
          style={headingTestStyle}
        >
          HELPING NEW BELIEVERS GROW IN FAITH
        </h1>
        <p className="text-lg">
          Seamlessly guide new members through their first steps in church life
          with personalized content and next steps, tailored to your
          congregation.
        </p>
        <div className="flex space-x-3">
          <Button
            onClick={goToDemoPage}
            className="w-[132px] bg-[#2A3037] border-none text-white"
          >
            Learn More
          </Button>
          <Button
            onClick={scrollToDownloadAppSection}
            className="w-[188px] gap-2 text-lg"
          >
            Download the App
          </Button>
        </div>
      </div>
      <div className="lg:w-1/2 justify-center hidden md:flex">
        <div className="relative">
          <img
            src="/assets/app-interface.png"
            alt="App interface"
            className="absolute left-6 top-5 rounded-3xl shadow-2xl z-50"
          />
          <img src="/assets/hand base.png" alt="App interface" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
