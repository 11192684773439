import { useEffect } from "react";
import ChurchAccountFormHeader from "../../components/church-account-form-header/ChurchAccountFormHeader";
import {
  formClassName,
  formContainerClass,
} from "../../constants/common-constants";
import { useGetApi } from "../../services/use-api";
import { cn } from "../../utils/common-utils";
import API_CONSTANTS from "../../constants/api-constant";
import { HistoryOfNotifications } from "../../models/church-admin.model";
import NotificationItem from "./NotificationItem";

const HistorySection = () => {
  const { data: notificationHistory, getData: getNotificationHistory } =
    useGetApi<HistoryOfNotifications[]>("");

  useEffect(() => {
    getNotificationHistory(API_CONSTANTS.HISTORY_OF_MANUAL_NOTIFICATIONS);
  }, []);

  return (
    <div className={cn(formClassName)}>
      <div className={cn(formContainerClass)}>
        <ChurchAccountFormHeader
          headerText="Notification History"
          showSubmitBtn={false}
        />
        <div className="h-[calc(100vh-200px)] overflow-y-auto rounded-md p-4 scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-300 scrollbar-thin">
          {notificationHistory?.body?.length ? (
            notificationHistory?.body?.map((notification) => (
              <NotificationItem
                id={notification.id}
                userId={notification.userId}
                title={notification.title}
                body={notification.body}
                createdAt={notification.createdAt}
                status={notification.status}
              />
            ))
          ) : (
            <span className="text-white text-lg">No Results</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default HistorySection;
