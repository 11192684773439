import { useEffect, useState } from "react";
import { ChurchAdminAccoundSettingEnum } from "../../models/church-admin.model";
import { cn } from "../../utils/common-utils";
import ChurchInfo from "./ChurchInfo";
import ManageAccountCred from "./ManageAccountCred";
import ChurchImages from "./ChurchImages";
import NextStepLinks from "./NextStepLinks";
import PlanSelection from "./PlanSelection";
import { useNavigate, useSearchParams } from "react-router-dom";
import NotFound from "../NotFound";
import { ROUTES } from "../../constants/routes-constants";
import HistorySection from "./HistorySection";
import NotificationSection from "./NotificationSection";

const ChurchAccount = () => {
  const [selectedItem, setSelectedItem] =
    useState<ChurchAdminAccoundSettingEnum | null>(
      ChurchAdminAccoundSettingEnum.CHURCH_INFO
    );
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const selectedItemClass =
    "bg-[#24282D] px-6 py-3 w-fit rounded-[6px] text-white cursor-pointer";
  const nonSelectedItemClass = "px-4 py-3 cursor-pointer text-[#90969D]";

  const setAccountOption = (tab: number) => {
    navigate(ROUTES.CHURCH_ADMIN_ACCOUNT(tab));
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    switch (tab) {
      case "1":
        setSelectedItem(ChurchAdminAccoundSettingEnum.CHURCH_INFO);
        break;
      case "2":
        setSelectedItem(ChurchAdminAccoundSettingEnum.USER_PASS);
        break;
      case "3":
        setSelectedItem(ChurchAdminAccoundSettingEnum.CHURCH_IMAGES);
        break;
      case "4":
        setSelectedItem(ChurchAdminAccoundSettingEnum.NEXT_STEP_LINKS);
        break;
      case "5":
        setSelectedItem(ChurchAdminAccoundSettingEnum.SUBSCRIPTION);
        break;
      case "6":
        setSelectedItem(ChurchAdminAccoundSettingEnum.NOTIFICATION);
        break;
      case "7":
        setSelectedItem(ChurchAdminAccoundSettingEnum.HISTORY_NOTIFICATION);
        break;
      default:
        setSelectedItem(null);
        break;
    }
  }, [searchParams]);

  return (
    <>
      {!selectedItem ? (
        <NotFound />
      ) : (
        <div className="bg-background min-h-screen text-[#8b8b8b] p-8">
          <div className="flex w-full justify-center mx-auto space-x-24">
            <div>
              {/* Header */}
              <header className="flex w-fit h-fit mb-8">
                <img src="/assets/logo.png" alt="logo" />
              </header>
              {/* Left column */}
              <div className="pr-8 pt-10">
                <nav className="space-y-4">
                  <div
                    onClick={() =>
                      setAccountOption(
                        ChurchAdminAccoundSettingEnum.CHURCH_INFO
                      )
                    }
                    className={cn(
                      selectedItem === ChurchAdminAccoundSettingEnum.CHURCH_INFO
                        ? selectedItemClass
                        : nonSelectedItemClass
                    )}
                  >
                    Church Info
                  </div>
                  <div
                    onClick={() =>
                      setAccountOption(ChurchAdminAccoundSettingEnum.USER_PASS)
                    }
                    className={cn(
                      selectedItem === ChurchAdminAccoundSettingEnum.USER_PASS
                        ? selectedItemClass
                        : nonSelectedItemClass
                    )}
                  >
                    Username/Password
                  </div>
                  <div
                    onClick={() =>
                      setAccountOption(
                        ChurchAdminAccoundSettingEnum.CHURCH_IMAGES
                      )
                    }
                    className={cn(
                      selectedItem ===
                        ChurchAdminAccoundSettingEnum.CHURCH_IMAGES
                        ? selectedItemClass
                        : nonSelectedItemClass
                    )}
                  >
                    Church Images
                  </div>
                  <div
                    onClick={() =>
                      setAccountOption(
                        ChurchAdminAccoundSettingEnum.NEXT_STEP_LINKS
                      )
                    }
                    className={cn(
                      selectedItem ===
                        ChurchAdminAccoundSettingEnum.NEXT_STEP_LINKS
                        ? selectedItemClass
                        : nonSelectedItemClass
                    )}
                  >
                    Next Step Links
                  </div>
                  <div
                    onClick={() =>
                      setAccountOption(
                        ChurchAdminAccoundSettingEnum.SUBSCRIPTION
                      )
                    }
                    className={cn(
                      selectedItem ===
                        ChurchAdminAccoundSettingEnum.SUBSCRIPTION
                        ? selectedItemClass
                        : nonSelectedItemClass
                    )}
                  >
                    Subscription
                  </div>
                  <div
                    onClick={() =>
                      setAccountOption(
                        ChurchAdminAccoundSettingEnum.NOTIFICATION
                      )
                    }
                    className={cn(
                      selectedItem ===
                        ChurchAdminAccoundSettingEnum.NOTIFICATION
                        ? selectedItemClass
                        : nonSelectedItemClass
                    )}
                  >
                    Notification
                  </div>
                  <div
                    onClick={() =>
                      setAccountOption(
                        ChurchAdminAccoundSettingEnum.HISTORY_NOTIFICATION
                      )
                    }
                    className={cn(
                      selectedItem ===
                        ChurchAdminAccoundSettingEnum.HISTORY_NOTIFICATION
                        ? selectedItemClass
                        : nonSelectedItemClass
                    )}
                  >
                    History
                  </div>
                </nav>
              </div>
            </div>

            {/* RIGHT COLUMN */}
            <div className="w-[50%]">
              {selectedItem === ChurchAdminAccoundSettingEnum.CHURCH_INFO ? (
                <ChurchInfo />
              ) : selectedItem === ChurchAdminAccoundSettingEnum.USER_PASS ? (
                <ManageAccountCred />
              ) : selectedItem ===
                ChurchAdminAccoundSettingEnum.CHURCH_IMAGES ? (
                <ChurchImages />
              ) : selectedItem ===
                ChurchAdminAccoundSettingEnum.NEXT_STEP_LINKS ? (
                <NextStepLinks />
              ) : selectedItem ===
                ChurchAdminAccoundSettingEnum.SUBSCRIPTION ? (
                <PlanSelection />
              ) : selectedItem ===
                ChurchAdminAccoundSettingEnum.NOTIFICATION ? (
                <NotificationSection />
              ) : selectedItem ===
                ChurchAdminAccoundSettingEnum.HISTORY_NOTIFICATION ? (
                <HistorySection />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChurchAccount;
