import { ValidationRule } from "react-hook-form";

export const phoneNumberRegex =
  /^(?:\+1\s?)?(?:\(?\d{3}\)?[\s.-]?)\d{3}[\s.-]?\d{4}$/;
export const emailRegex = /\b[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\b/;
export const urlRegex = /\b(?:https?|ftp):\/\/(?:www\.)?[^\s/$.?#].[^\s]*\b/;
export const zipCodeRegex = /^\d{5,}([- \dA-Za-z]*)$/;

export const phoneNumberPattern: ValidationRule<RegExp> = {
  value: phoneNumberRegex,
  message: "Enter Valid Phone Number",
};

export const emailPattern: ValidationRule<RegExp> = {
  value: emailRegex,
  message: "Enter Valid Email",
};

export const urlPattern: ValidationRule<RegExp> = {
  value: urlRegex,
  message: "Enter Valid Url",
};

export const zipCodePattern: ValidationRule<RegExp> = {
  value: zipCodeRegex,
  message: "Enter at least 5 digits",
};
