import React from "react";
import { ChevronUp } from "lucide-react";

const TermsAndConditions = () => {
  const [showScrollTop, setShowScrollTop] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-3xl mx-auto px-4 py-12">
        <div className="bg-white shadow-lg rounded-lg p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">
            Terms of Use for Your First Thirty Days
          </h1>

          <div className="text-sm text-gray-600 mb-8">
            Last Updated: 10/17/2024
          </div>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Acceptance of the Terms of Use
            </h2>
            <p className="text-gray-600 leading-relaxed">
              These terms of use are entered into by and between You and{" "}
              <span className="font-semibold text-black ">
                Your First 30 Days LLC
              </span>{" "}
              ("Company," "we," or "us"). These terms govern your access to and
              use of the website app.yourfirstthirtydays.com ("Site") and the
              Your First Thirty Days app, including all content, functionality,
              and services offered on or through the Site or app (collectively,
              the "Services").
            </p>

            <div className="mt-4 bg-blue-50 border-blue-200 p-2">
              <div className="text-blue-800">Important Notice</div>
              <div className="text-blue-700">
                By using the Services, you accept and agree to be bound by these
                Terms of Use and our Privacy Policy. If you do not agree, you
                must not access or use the Services.
              </div>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              1. Age Restrictions
            </h2>
            <p className="text-gray-600 leading-relaxed">
              You must be at least 13 years old to use the Services. If you are
              under 18, you must have parental or legal guardian consent to use
              the Services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              2. Clones and AI Content
            </h2>
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
              <p className="text-yellow-700">
                Your First Thirty Days uses AI-powered clones for user
                interactions. These clones do not represent real individuals and
                may not reflect their personal views or attributes. All
                information provided by the clones is for informational and
                entertainment purposes only.
              </p>
            </div>
            <p className="text-gray-600">
              You agree not to rely on these clones for legal, financial,
              medical, or other important decisions.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              3. Account Creation and Security
            </h2>
            <p className="text-gray-600 leading-relaxed mb-4">
              To use the Services, you must create an account by providing
              accurate, current, and complete information. You are responsible
              for maintaining the confidentiality of your account credentials
              and for all activities that occur under your account.
            </p>
            <p className="text-gray-600 leading-relaxed">
              You agree to notify us immediately of any unauthorized access to
              your account.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              6. Acceptable Use
            </h2>
            <p className="text-gray-600 mb-4">You agree not to:</p>
            <ul className="list-disc ml-6 text-gray-600 space-y-2">
              <li className="text-gray-600">
                Provide false, misleading, or inaccurate information.
              </li>
              <li className="text-gray-600">
                Reverse engineer, decompile, or attempt to extract the source
                code of the Services.
              </li>
              <li className="text-gray-600">
                Use the Services to engage in any unlawful, harmful, or abusive
                activities.
              </li>
              <li className="text-gray-600">
                Upload any harmful or malicious content.
              </li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              7. Payment and Fees
            </h2>
            <div className="bg-gray-50 border border-gray-200 rounded-lg p-4">
              <p className="text-gray-600 leading-relaxed">
                Certain features of the Services may require payment. If you
                sign up for a subscription, you agree to pay the applicable
                fees. Subscription fees are automatically renewed unless
                canceled, and all payments are non-refundable.
              </p>
            </div>
          </section>

          {/* Additional sections continue with similar styling... */}

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              12. Contact Us
            </h2>
            <p className="text-gray-600 leading-relaxed">
              For any questions regarding these Terms of Use, please contact us
              at{" "}
              <a
                href="mailto:support@yourfirstthirtydays.com"
                className="text-blue-600 hover:text-blue-800"
              >
                support@yourfirstthirtydays.com
              </a>
            </p>
          </section>
        </div>
      </div>

      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 bg-gray-800 text-white p-3 rounded-full shadow-lg hover:bg-gray-700 transition-colors"
          aria-label="Scroll to top"
        >
          <ChevronUp size={24} />
        </button>
      )}
    </div>
  );
};

export default TermsAndConditions;
