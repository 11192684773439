import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/widgets/Button";
import InputField from "../../components/widgets/InputField";
import API_CONSTANTS from "../../constants/api-constant";
import { ROUTES } from "../../constants/routes-constants";
import { usePostApi } from "../../services/use-api";
import { RequestDemoFormDataModel } from "../../models/request-demo.model";
import {
  emailPattern,
  phoneNumberPattern,
} from "../../constants/regex-pattern";
import RequestDemoSuccess from "./RequestDemoSuccess";

const Demo = () => {
  const form = useForm<RequestDemoFormDataModel>();
  const navigate = useNavigate();
  const {
    data: requestDemoResData,
    postData: requestDemo,
    isLoading: isLogging,
  } = usePostApi({
    path: API_CONSTANTS.REQUEST_DEMO,
  });

  const onSubmit: SubmitHandler<RequestDemoFormDataModel> = (
    data: RequestDemoFormDataModel
  ) => {
    requestDemo(data);
  };

  const goToLandingPage = () => {
    navigate(ROUTES.LANDING_PAGE);
  };

  return (
    <>
      {requestDemoResData && requestDemoResData.success ? (
        <RequestDemoSuccess />
      ) : (
        <div className="flex flex-col lg:flex-row h-screen">
          {/* Left Section */}
          <div className="lg:w-1/2 relative">
            <img
              src="/assets/demo-left-main.png"
              alt="main img"
              className="object-cover h-full w-full"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-end p-8">
              <img
                src="/assets/demo-left-sub.png"
                alt="sub img"
                className="max-w-sm"
              />
            </div>
            <div
              onClick={goToLandingPage}
              className="absolute top-[10px] left-[10px] bg-black w-fit rounded-[40px]"
            >
              <img height={54} width={54} src="/assets/logo.png" alt="logo" />
            </div>
          </div>

          <div className="lg:w-1/2 bg-background text-white flex flex-col justify-center p-8 lg:p-12">
            <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
              <InputField<RequestDemoFormDataModel>
                name="name"
                form={form}
                label="Name"
                placeholder="Name"
                validation={{
                  required: "Required",
                }}
              />

              <InputField<RequestDemoFormDataModel>
                name="churchName"
                form={form}
                label="Church"
                placeholder="Church Name"
                validation={{
                  required: "Required",
                }}
              />

              <InputField<RequestDemoFormDataModel>
                name="phoneNumber"
                form={form}
                label="Phone"
                placeholder="Phone"
                validation={{
                  required: "Required",
                  pattern: phoneNumberPattern,
                }}
              />

              <InputField<RequestDemoFormDataModel>
                name="emailAddress"
                form={form}
                label="Email Address"
                placeholder="Email Addres"
                validation={{
                  required: "Required",
                  pattern: emailPattern,
                }}
              />

              <p className="text-sm text-gray-400">
                We respect your data. By submitting this form, you agree that we
                will contact you in relation to our app in accordance with our{" "}
                <Link
                  className="text-sm hover:text-gray-400 underline text-[#3AC7A6]"
                  to={ROUTES.PRIVACY_POLICY}
                >
                  privacy policy.
                </Link>
              </p>
              <Button
                className="w-[30%]"
                isLoading={isLogging}
                type="submit"
                label="Submit"
              />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Demo;
