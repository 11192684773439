import { useEffect, useState } from "react";
import PlanCard from "../../components/plan-card/PlanCard";
import API_CONSTANTS from "../../constants/api-constant";
import {
  PlanDetailsDataModel,
  PlanSubscribeResDataModel,
} from "../../models/payment-info.model";
import { useGetApi } from "../../services/use-api";

import { AlertTriangle, CheckCircle, CreditCard } from "lucide-react";
import AppDialog from "../../components/app-dialog/AppDialog";
import FormWrapperLoading from "../../components/form-wrapper/FormWrapperLoading";
import Button from "../../components/widgets/Button";
import { useGetPaymentDetails } from "../../services/payment.service";
import { formatDate, getLocalDateFromUTC } from "../../utils/date-utils";
import { cn } from "../../utils/common-utils";
import {
  formClassName,
  formContainerClass,
} from "../../constants/common-constants";
import ChurchAccountFormHeader from "../../components/church-account-form-header/ChurchAccountFormHeader";
import ApiResError from "../../components/api-res-error/ApiResError";

const PlanSelection = () => {
  const { paymentDetails, checkSubscribe, checkSubscribeError } =
    useGetPaymentDetails();
  const {
    data: planDetailsResData,
    getData: getPlanDetails,
    isLoading: isLoadingPlanDetails,
    error: planDetailsError,
  } = useGetApi<PlanDetailsDataModel[]>(API_CONSTANTS.GET_PLAN_DETAILS);

  const {
    data: subscribeResData,
    getData: subscribePlan,
    isLoading: isSubscribing,
  } = useGetApi<PlanSubscribeResDataModel>("");

  const [dialogData, setDialogData] = useState<string | null>(null);
  const [dialogState, setDialogState] = useState<boolean>(false);

  const openDialog = (subscriptionPriceId: string) => {
    setDialogState(true);
    setDialogData(subscriptionPriceId);
  };

  const onConfirm = () => {
    if (dialogData) {
      subscribePlan(API_CONSTANTS.SUBSCRIBE_PLAN(dialogData));
    }
  };

  const closeDialog = () => {
    setDialogData(null);
    setDialogState(false);
  };

  useEffect(() => {
    getPlanDetails();
  }, [getPlanDetails]);

  useEffect(() => {
    if (subscribeResData && subscribeResData.body && subscribeResData.success) {
      closeDialog();
      sessionStorage.setItem("isPaymentInProcess", "true");
      window.open(subscribeResData.body.subscriptionUrl, "_self");
    }
  });
  // Check Subscribtion
  useEffect(() => {
    checkSubscribe();
  }, [checkSubscribe]);

  return (
    <div className={cn("w-[75%]", formClassName)}>
      <ChurchAccountFormHeader
        headerText="Subscription"
        isLoading={false}
        showSubmitBtn={false}
      />
      {isLoadingPlanDetails ? (
        <FormWrapperLoading />
      ) : checkSubscribeError || planDetailsError ? (
        <div className={cn(formContainerClass)}>
          <ApiResError
            title="Something went wrong"
            message={checkSubscribeError || planDetailsError}
          />
        </div>
      ) : (
        <div className={cn("pt-4")}>
          {planDetailsResData &&
          planDetailsResData?.body &&
          planDetailsResData.body.length !== 0 ? (
            <div className="container mx-auto">
              {paymentDetails?.isExpired ? (
                // Expired Card
                <div className=" border-l-4 border-yellow-500 text-yellow-700 p-6 rounded-lg">
                  <div className="flex items-center mb-4">
                    <AlertTriangle className="mr-2" />
                    <h2 className="text-2xl font-semibold">
                      Your Premium Membership has expired
                    </h2>
                  </div>
                  <p className="mb-4">
                    Renew now to continue enjoying all our premium features
                    without interruption.
                  </p>
                  <Button
                    className="w-fit bg-yellow-500"
                    onClick={() =>
                      openDialog(paymentDetails.subscriptionPriceId)
                    }
                  >
                    <div className="flex m-2">
                      <CreditCard color="#24282D" className="mr-2" />
                      <div className="text-[#24282D]">Renew Subscription</div>
                    </div>
                  </Button>
                </div>
              ) : paymentDetails?.isSubscribed ? (
                // Already Subscribed Card
                <div className="border-l-4 border-green-500 text-green-700 p-6 rounded-lg">
                  <div className="flex items-center mb-4">
                    <CheckCircle className="mr-2" />
                    <h2 className="text-2xl font-semibold">
                      You're a Premium Member!
                    </h2>
                  </div>
                  <p className="mb-4">
                    {`Enjoy full access to all our premium features. Your Subscription will
                    expired on ${formatDate(
                      getLocalDateFromUTC(paymentDetails.expirationDate)
                    )}.`}
                  </p>
                </div>
              ) : (
                // Plans Details
                <div className={cn(formContainerClass, "space-x-2")}>
                  {planDetailsResData?.body?.map((plan) => (
                    <PlanCard
                      key={plan.planTitle}
                      onSelect={() => {
                        openDialog(plan.subscriptionPriceId);
                      }}
                      planDetail={plan}
                    />
                  ))}
                </div>
              )}

              <AppDialog
                description="Are you sure you want to go with paid subscription?"
                title="Subscription"
                isLoading={isSubscribing}
                isOpen={dialogState}
                onClose={closeDialog}
                onConfirm={onConfirm}
              />
            </div>
          ) : (
            <div className="flex justify-center items-center text-lg h-[40vh]">
              Plans not avialable
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlanSelection;
