import { cn } from "../../utils/common-utils";

interface BlurEffectProps {
  className?: string;
}

const BlurEffect = ({ className }: BlurEffectProps) => {
  return (
    <div
      className={cn(
        "absolute top-[30%] left-[34%] w-[50%] h-[50%] bg-gray-300 blur-3xl opacity-20 -z-10",
        className
      )}
    ></div>
  );
};
export default BlurEffect;
