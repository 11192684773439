import {
  headingTestStyle,
  numberHeadingTextClass,
} from "../../constants/landing-page-constants";
import { cn } from "../../utils/common-utils";
import VerticalBar from "./VerticalBar";

const HowItWorks = () => {
  return (
    <section className="bg-[#1B1F24] py-20">
      <div className=" min-h-screen flex justify-center p-8">
        <div className="max-w-4xl w-full space-y-28">
          {/* Header */}
          <div className="text-center">
            <div className="text-primary text-sm mb-4  ">
              Your First 30 Days
            </div>
            <h2
              className="text-4xl font-bold text-white tracking-wide"
              style={headingTestStyle}
            >
              HOW IT WORKS
            </h2>
          </div>

          {/* Description Cards */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12 gap-y-24 ml-12 ">
            <div className="relative space-y-2">
              <h1 className={cn(numberHeadingTextClass)}>1</h1>
              <h3 className="text-primary font-medium text-lg">
                Download the App
              </h3>
              <p className="text-sm">
                New believers download the app via a QR code after accepting
                Jesus.
              </p>
            </div>
            <div className="relative space-y-2">
              <h1 className={cn(numberHeadingTextClass)}>2</h1>
              <h3 className="text-primary font-medium text-lg">Commitments</h3>
              <p className="text-sm">
                Over 30 days, they go through four key commitments: baptism,
                community, serving, and sharing their faith.
              </p>
            </div>
            <div className="relative space-y-2">
              <h1 className={cn(numberHeadingTextClass)}>3</h1>
              <h3 className="text-primary font-medium text-lg">Daily Time</h3>
              <p className="text-sm">
                Each week, users receive daily video content, scripture, and
                devotional thoughts.
              </p>
            </div>
            <div className="relative space-y-2">
              <h1 className={cn(numberHeadingTextClass)}>4</h1>
              <h3 className="text-primary font-medium text-lg">Next Steps</h3>
              <p className="text-gray-400 text-sm">
                At the end of each week, they take their next step, personalized
                to their local church (baptism sign-up, join a group, serve, or
                share their faith).
              </p>
            </div>
          </div>

          {/* Progress Steps */}
          <div className="relative md:border-[2px] md:rounded-[90px] md:border-[#FFFFFF17] md:p-20 md:space-y-0 space-y-4">
            <div className="bg-[#FFFFFF17] h-[1px] w-[70%] m-auto md:hidden"></div>
            <div>
              {/* Steps */}
              <div className="relative grid grid-cols-4 md:gap-4 gap-12">
                {[
                  {
                    icon: "/assets/svg/baptism-icon.svg",
                    label: "BAPTISM",
                  },
                  {
                    icon: "/assets/svg/community-icon.svg",
                    label: "COMMUNITY",
                  },
                  {
                    icon: "/assets/svg/serving-icon.svg",
                    label: "SERVING",
                  },
                  {
                    icon: "/assets/svg/sharing-icon.svg",
                    label: "SHARING",
                  },
                ].map((step, index) => (
                  <div key={step.label} className="flex flex-col items-center">
                    <div
                      className={`md:w-12 md:h-12 rounded-full flex items-center justify-center mb-3`}
                    >
                      <img alt="icon" src={step.icon} className="w-6 h-6" />
                    </div>
                    <span className={`md:text-lg text-sm font-bold`}>
                      {step.label}
                    </span>
                  </div>
                ))}
              </div>

              {/* Progress Bar Component */}
              <div className="relative mt-16">
                {/* Progress Bar Background */}
                <div className="w-full  h-[15px] absolute top-2 bg-gray-800 rounded-[60px]" />
                {/* Active Progress Bar */}
                <div className="absolute w-1/2 top-2 bg-gradient-to-r from-emerald-800 via-primary to-white h-[15px] rounded-[60px]" />

                {/* Baptism Vertical Bar */}
                <div className="absolute md:left-9 left-0">
                  <VerticalBar verticalBarClass="border-primary" />
                </div>

                {/* Community Vertical Bar */}
                <div className="absolute md:left-[30%] left-[22%]">
                  <VerticalBar verticalBarClass="border-primary" />
                </div>

                {/* Serving Vertical Bar */}
                <div className="absolute md:left-[56%] left-[50%]">
                  <VerticalBar />
                </div>

                {/* Sharing Vertical Bar */}
                <div className="absolute md:left-[82%] left-[75%]">
                  <VerticalBar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
