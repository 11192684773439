import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import InputField from "../../components/widgets/InputField";
import { ChurchAdminUpdateCreadFormDataModel } from "../../models/auth-form.model";
import Button from "../widgets/Button";
import { cn } from "../../utils/common-utils";
import { formClassName } from "../../constants/common-constants";

interface CreadUpdateFormProps {
  form: UseFormReturn<ChurchAdminUpdateCreadFormDataModel, any, undefined>;
  buttonLabel: string;
  buttonClassName?: string;
  formHeaderLabel: string;
  onSubmit: (data: ChurchAdminUpdateCreadFormDataModel) => void;
  isLoading?: boolean;
}

const CreadUpdateForm = ({
  form,
  formHeaderLabel,
  buttonLabel,
  buttonClassName,
  onSubmit,
  isLoading = false,
}: CreadUpdateFormProps) => {
  const confirmPasswordWatch = form.watch("confirmPassword");
  const passwordWatch = form.watch("password");

  useEffect(() => {
    const password = form.getValues("password");
    if (password?.length !== 0 && confirmPasswordWatch !== password) {
      form.setError("confirmPassword", {
        message: "Password Not Matched",
      });
    } else {
      form.setError("confirmPassword", {
        message: "",
      });
    }
  }, [confirmPasswordWatch, form]);

  useEffect(() => {
    if (passwordWatch?.length === 0) {
      form.setError("confirmPassword", {
        message: "",
      });
    }
  }, [form, passwordWatch?.length]);

  return (
    <div className="pb-3">
      <form
        className={cn(formClassName)}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {/* UserName */}
        <InputField
          name="username"
          label="Username"
          placeholder="Username"
          form={form}
          validation={{
            required: "Required",
          }}
          readonly={true}
        />
        {/* Password */}
        <InputField
          name="password"
          placeholder="Password"
          label="Password"
          type="password"
          form={form}
          validation={{
            required: "Required",
          }}
        />

        {/* Confirm Password */}
        <InputField
          name="confirmPassword"
          placeholder="Confirm Password"
          label="Confirm Password"
          type="password"
          form={form}
          validation={{
            required: "Required",
          }}
        />

        <Button
          isLoading={isLoading}
          type="submit"
          className={buttonClassName}
          label={buttonLabel}
        />
      </form>
    </div>
  );
};

export default CreadUpdateForm;
