import React from "react";
import { ChevronUp } from "lucide-react";

const PrivacyPolicy = () => {
  const [showScrollTop, setShowScrollTop] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-3xl mx-auto px-4 py-12">
        <div className="bg-white shadow-lg rounded-lg p-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-8">
            Privacy Policy for Your First Thirty Days
          </h1>

          <div className="text-sm text-gray-600 mb-8">
            Last Updated: 10/17/2024
          </div>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              Introduction
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Your First 30 Days LLC ("Company," "we," or "us") is committed to
              respecting your privacy. This Privacy Policy describes how we
              collect, use, and disclose your personal information ("Personal
              Information") when you access the website
              app.yourfirstthirtydays.com ("Site") or use the Your First Thirty
              Days app and related services (collectively, the "Services").
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              1. Personal Information We Collect
            </h2>
            <p className="text-gray-600 mb-4">
              We may collect the following types of Personal Information:
            </p>

            <div className="ml-4">
              <h3 className="font-medium text-gray-800 mb-2">
                Personal Information You Provide:
              </h3>
              <p className="text-gray-600 mb-4">
                When you create an account, communicate with us, or use the
                Services, we collect information like your name, email address,
                and any messages or queries you send to us.
              </p>

              <h3 className="font-medium text-gray-800 mb-2">
                Information Collected Automatically:
              </h3>
              <ul className="list-disc ml-6 text-gray-600">
                <li className="mb-2 text-gray-600">
                  <span className="font-bold text-gray-600">Log Data:</span>{" "}
                  Information such as IP addresses, browser types, and the times
                  you interact with the Site or Services.
                </li>
                <li className="text-gray-600">
                  <span className="font-bold text-gray-600">
                    Device Information:
                  </span>{" "}
                  The type of device you use, its operating system, and browser
                  type.
                </li>
              </ul>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              2. Cookies and Tracking Technologies
            </h2>
            <p className="text-gray-600 leading-relaxed">
              We use cookies and similar tracking technologies to enhance your
              experience and collect aggregated data about site usage. You can
              adjust your browser settings to manage or reject cookies, but
              disabling cookies may limit some features of the Services.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              3. How We Use Your Personal Information
            </h2>
            <ul className="list-disc ml-6 text-gray-600">
              <li className="mb-2 text-gray-600">
                To provide and maintain the Services.
              </li>
              <li className="mb-2 text-gray-600">
                To communicate with you regarding your account or any support
                inquiries.
              </li>
              <li className="mb-2 text-gray-600">
                To analyze and improve the functionality of the Services.
              </li>
              <li className="mb-2 text-gray-600">
                To enhance security and prevent fraud.
              </li>
              <li className="text-gray-600">
                For research and analysis to better understand user behavior and
                preferences.
              </li>
            </ul>
          </section>

          {/* Additional sections continue with the same pattern... */}

          <section className="mb-8">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
              10. Contact Us
            </h2>
            <p className="text-gray-600 leading-relaxed">
              If you have any questions about this Privacy Policy, please
              contact us at{" "}
              <a
                href="mailto:support@yourfirstthirtydays.com"
                className="text-blue-600 hover:text-blue-800"
              >
                support@yourfirstthirtydays.com
              </a>
            </p>
          </section>
        </div>
      </div>

      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 right-8 bg-gray-800 text-white p-3 rounded-full shadow-lg hover:bg-gray-700 transition-colors"
          aria-label="Scroll to top"
        >
          <ChevronUp size={24} />
        </button>
      )}
    </div>
  );
};

export default PrivacyPolicy;
