import { ReactNode, Suspense } from "react";
import { cn } from "../../utils/common-utils";
import FormWrapperLoading from "./FormWrapperLoading";

interface FormWrapperProps {
  formHeaderLabel: string;
  children: ReactNode;
  className?: string;
  showAdminLabel?: boolean;
  headerClassName?: string;
}
const FormWrapper = ({
  formHeaderLabel,
  children,
  className,
  showAdminLabel,
  headerClassName,
}: FormWrapperProps) => {
  return (
    <Suspense fallback={<FormWrapperLoading />}>
      <div className="min-h-screen p-4 md:p-10 flex items-center justify-center">
        <div className={cn("w-[30%] space-y-6", className)}>
          <div className="space-y-1">
            {showAdminLabel && <label className="text-sm">Admin</label>}
            <h1 className={cn("text-start text-[40px]", headerClassName)}>
              {formHeaderLabel}
            </h1>
          </div>
          {children}
        </div>
      </div>
    </Suspense>
  );
};

export default FormWrapper;
