import { useForm } from "react-hook-form";
import { NextStepLinksFormDataModel } from "../../models/church-admin.model";
import InputField from "../../components/widgets/InputField";
import {
  formClassName,
  formContainerClass,
} from "../../constants/common-constants";
import { cn } from "../../utils/common-utils";
import { useGetApi, usePutApi } from "../../services/use-api";
import API_CONSTANTS from "../../constants/api-constant";
import { SingleChurchDetailResDataModel } from "../../models/platform-admin.model";
import { useEffect, useMemo } from "react";
import { urlPattern } from "../../constants/regex-pattern";
import { useUserContext } from "../../context/UserProvider";
import ChurchAccountFormHeader from "../../components/church-account-form-header/ChurchAccountFormHeader";

const NextStepLinks = () => {
  const { userData } = useUserContext();
  const form = useForm<NextStepLinksFormDataModel>();
  const {
    data: updatedNextStepLinksResData,
    putData: updateNextStepLinksResData,
    isLoading: isUpdating,
  } = usePutApi({
    path: "",
  });

  const editId = useMemo(() => userData?.churchId ?? "", [userData]);

  const { data: nextStepLinksResData, getData: getNextStepLinks } =
    useGetApi<SingleChurchDetailResDataModel>(
      API_CONSTANTS.GET_CHURCH_DETAIL(editId)
    );

  const onSubmit = (data: NextStepLinksFormDataModel) => {
    const formData = new FormData();
    const churchResData = nextStepLinksResData?.body;
    if (churchResData) {
      const churchDataPayload = {
        name: churchResData.name,
        email: churchResData.email,
        userName: churchResData.userName,
        phone: churchResData.phone,
        contactPersonName: churchResData.contactPersonName,
        address: churchResData.address,
        city: churchResData.city,
        state: churchResData.state,
        zipCode: churchResData.zipCode,
        baptismSignUpLink: data.baptismSignUpLink,
        communitySignUpLink: data.communitySignUpLink,
        serveSignUpLink: data.serveSignUpLink,
        link: data.link,
        requestPrayerLink: churchResData.requestPrayerLink,
      };
      formData.append("churchData", JSON.stringify(churchDataPayload));
      updateNextStepLinksResData(formData, API_CONSTANTS.UPDATE_CHURCH(editId));
    }
  };

  // Get next step links
  useEffect(() => {
    getNextStepLinks();
  }, [getNextStepLinks]);

  // Call Get Api after successfully Updated
  useEffect(() => {
    if (updatedNextStepLinksResData && updatedNextStepLinksResData.success) {
      getNextStepLinks();
    }
  }, [getNextStepLinks, updatedNextStepLinksResData]);

  // Set form Data OnLoad
  useEffect(() => {
    if (nextStepLinksResData && nextStepLinksResData.success) {
      const data: NextStepLinksFormDataModel = {
        baptismSignUpLink: nextStepLinksResData.body?.baptismSignUpLink ?? "",
        communitySignUpLink:
          nextStepLinksResData.body?.communitySignUpLink ?? "",
        link: nextStepLinksResData.body?.link ?? "",
        serveSignUpLink: nextStepLinksResData.body?.serveSignUpLink ?? "",
      };
      form.reset(data);
    }
  }, [form, nextStepLinksResData]);

  return (
    <div>
      <form
        className={cn(formClassName)}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <ChurchAccountFormHeader
          headerText="Next Step Links"
          isLoading={isUpdating}
        />
        <div className={formContainerClass}>
          {/* Baptism Sign Up Link  */}
          <InputField
            form={form}
            name="baptismSignUpLink"
            label="Baptism Sign Up Link"
            validation={{
              pattern: urlPattern,
            }}
          />
          {/* Community Sign Up Link  */}
          <InputField
            form={form}
            name="communitySignUpLink"
            label="Community Sign Up Link"
            validation={{
              pattern: urlPattern,
            }}
          />
          {/* Serve Sign Up Link */}
          <InputField
            form={form}
            name="serveSignUpLink"
            label="Serve Sign Up Link"
            validation={{
              pattern: urlPattern,
            }}
          />
          {/* Link */}
          <InputField
            form={form}
            name="link"
            label="Link"
            validation={{
              pattern: urlPattern,
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default NextStepLinks;
