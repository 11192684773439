import { useNavigate } from "react-router-dom";
import NoAccessPage from "../components/no-access/NoAccessPage";
import { ROUTES } from "../constants/routes-constants";

const AccessDenied = () => {
  const navigate = useNavigate();
  const gotToLogin = () => {
    navigate(ROUTES.LOGIN, {
      replace: true,
    });
  };
  return <NoAccessPage btnLabel="Go Back" onClick={gotToLogin} />;
};

export default AccessDenied;
