import Button from "../components/widgets/Button";
import HowItWorks from "../components/landing-page-components/HowItWorks";
import ChurchMission from "../components/landing-page-components/ChurchMission";
import DownloadApp from "../components/landing-page-components/DownloadApp";
import Footer from "../components/landing-page-components/Footer";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes-constants";
import HeroSection from "../components/landing-page-components/HeroSection";
import { useRef } from "react";

const LandingPage = () => {
  const navigator = useNavigate();
  const downloadAppSectionRef = useRef<HTMLDivElement>(null);

  const goToLogin = () => {
    navigator(ROUTES.LOGIN);
  };
  const goToDemo = () => {
    navigator(ROUTES.DEMO);
  };

  const scrollToDownloadAppSection = () => {
    if (downloadAppSectionRef.current) {
      downloadAppSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="min-h-screen backdrop-blur-lg bg-opacity-20 text-white">
      {/* Header */}
      <header className="flex justify-between items-center md:pl-14 pl-6 pr-6 md:pr-14 pt-8">
        <img alt="logo" src="/assets/secondary-logo.png" />
        <div className="flex md:gap-6 gap-3">
          <Button
            variant="secondary"
            className="border-none hover:text-white/80 mt-0"
            onClick={goToLogin}
          >
            Log in
          </Button>
          <Button
            variant="secondary"
            className="w-[152px] mt-0 border-primary"
            onClick={goToDemo}
          >
            Get a Demo
          </Button>
        </div>
      </header>

      {/* Hero Section */}
      <HeroSection scrollToDownloadAppSection={scrollToDownloadAppSection} />

      {/* How It Works */}
      <HowItWorks />

      {/* Built For Section */}
      <ChurchMission />

      {/* Download Section */}
      <div ref={downloadAppSectionRef}>
        <DownloadApp />
      </div>

      {/* Testimonials */}
      {/* <TestimonialsSection /> */}

      {/* FAQ Section */}
      {/* <FAQSection /> */}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;
