import { XCircle } from "lucide-react";
import { cn } from "../../utils/common-utils";

const ApiResError = ({
  statusCode = 500,
  title = "Something went wrong",
  message = "An error occurred while processing your request.",
}) => {
  const getErrorDetails = (code: number) => {
    switch (code) {
      case 400:
        return {
          title: "Bad Request",
          message: "The request could not be understood by the server.",
        };
      case 401:
        return {
          title: "Unauthorized",
          message: "Authentication is required to access this resource.",
        };
      case 403:
        return {
          title: "Forbidden",
          message: "You do not have permission to access this resource.",
        };
      case 404:
        return {
          title: "Not Found",
          message: "The requested resource could not be found.",
        };
      case 500:
        return {
          title: "Internal Server Error",
          message: "An unexpected error occurred on our servers.",
        };
      default:
        return {
          title: "Error",
          message: "An unexpected error occurred.",
        };
    }
  };

  const errorInfo = getErrorDetails(statusCode);

  return (
    <div className={cn("flex justify-center")}>
      <div className="max-w-md w-full px-6 py-8 rounded-lg text-center">
        <div className="flex justify-center mb-4">
          <XCircle color="#F87171" className="h-16 w-16" />
        </div>

        <h1 className="text-3xl font-bold mb-4">{title || errorInfo.title}</h1>

        <div className="mb-6">{message || errorInfo.message}</div>

        <div className="text-sm mb-8">Error Code: {statusCode}</div>
      </div>
    </div>
  );
};

export default ApiResError;
