import { ReactNode, useEffect } from "react";


export function ThemeProvider({ children }:{children: ReactNode}) {
  useEffect(() => {
    document.documentElement.classList.toggle('dark',true)
  },[])
  return (
      <div className={`dark`}>{children}</div>
  );
}
