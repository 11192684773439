import { cn } from "../../utils/common-utils";
import Button from "../widgets/Button";

interface AppDialogProps {
  isOpen: boolean;
  title: string;
  description: string;
  onClose: () => void;
  onConfirm: () => void;
  confirmTitle?: string;
  closeTitle?: string;
  isLoading: boolean;
  hideCloseBtn?: boolean;
  hideActionBtn?: boolean;
  children?: React.ReactNode;
  contentClassName?: string;
}

const AppDialog = ({
  isOpen,
  title,
  description,
  onClose,
  onConfirm,
  confirmTitle = "Confirm",
  closeTitle = "Close",
  isLoading,
  hideCloseBtn = false,
  hideActionBtn = false,
  children = <></>,
  contentClassName,
}: AppDialogProps) => {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-40 flex items-center justify-center">
          <div
            className={cn(
              "bg-background rounded-lg shadow-xl p-6 m-4 max-w-xl w-full fixed",
              contentClassName
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-white">{title}</h2>
              {!hideCloseBtn && (
                <button
                  onClick={onClose}
                  className="text-gray-400 hover:opacity-25 transition duration-150 ease-in-out"
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              )}
            </div>
            <p>{description}</p>
            {children}
            {!hideActionBtn && (
              <div className="mt-4 flex justify-end space-x-3">
                {!hideCloseBtn && (
                  <Button
                    variant="secondary"
                    label={closeTitle}
                    onClick={onClose}
                  />
                )}
                <Button
                  isLoading={isLoading}
                  label={confirmTitle}
                  onClick={onConfirm}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default AppDialog;
