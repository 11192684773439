import { CircleCheckBig } from "lucide-react";
import { headingTestStyle } from "../../constants/landing-page-constants";
import Button from "../widgets/Button";
import { ReactNode } from "react";
import BlurEffect from "../blur-effect/BlurEffect";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes-constants";

interface CardProps {
  content: ReactNode;
}

const ChurchMission = () => {
  const navigator = useNavigate();

  const goToDemo = () => {
    navigator(ROUTES.DEMO);
  };
  const Card = ({ content }: CardProps) => {
    return (
      <div className="border-[#3B3D3F] border-[1px] rp flex md:gap-12 gap-6 rounded-[900px] md:p-12 p-4 items-center">
        <CircleCheckBig
          color="#3AC7A6"
          className="md:w-[32px] md:h-[32px] w-[40px] h-[40px]"
        />
        <div className="text-lg">{content}</div>
      </div>
    );
  };

  return (
    <div className="relative">
      <BlurEffect className="w-[100%] h-[100%] top-0 left-0 bg-gradient-to-tr from-gray-300 via-background to-white" />
      <div className="relative container mx-auto md:px-16 px-6 py-36 lg:grid sm:grid-cols-2 grid-cols-1">
        <img
          src="/assets/church-mission-bg.jpeg"
          alt="Community"
          className="absolute rounded-[900px] w-[193px] h-[348px] object-cover -z-10  md:-top-3 md:left-10 right-0"
        />
        <div className="w-full">
          <div className="space-y-6 top-[48%] left-[8%]">
            <label className="text-primary font-bold">Your First 30 Days</label>
            <div
              className="md:text-7xl text-4xl leading-8"
              style={{
                ...headingTestStyle,
                fontSize: undefined,
                lineHeight: undefined,
              }}
            >
              <h1 className="text-white">{"BUILT FOR YOUR"}</h1>
              <h1 className="text-white">{"CHURCH’S"}</h1>
              <h1 className="text-white">{"MISSION"}</h1>
            </div>
            <p>
              our First 30 Days is designed to integrate new believers into your
              church quickly. The app connects them with baptism, small groups,
              ministries, and outreach in a personalized and seamless way.
            </p>
            <Button onClick={goToDemo} className="w-[162px]">
              Get a Demo
            </Button>
          </div>
        </div>
        <div className="w-full mt-10">
          <Card
            content={
              <p className="text-primary">
                Customizable next steps <span>for your congregation.</span>
              </p>
            }
          />
          <Card
            content={
              <span className="flex gap-2">
                <p className="text-primary">
                  Easy-to-use{" "}
                  <span>video, scripture, and devotional plan.</span>
                </p>
              </span>
            }
          />
          <Card
            content={
              <p className="text-primary">
                Immediate engagement <span>for new believers.</span>
              </p>
            }
          />
          <Card
            content={
              <p>
                Strengthens <span className="text-primary">community</span>{" "}
                <span>and</span>{" "}
                <span className="text-primary">involvement</span>
              </p>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ChurchMission;
