import { useCallback, useEffect, useState } from "react";
import AxiosClient from "./interceptor";
import { toast } from "react-toastify";
import { TOASTER_CONFIG } from "../constants/common-constants";
import { ResponseDataModel } from "../models/response.model";
import axios from "axios";
interface Options {
  isToaster?: boolean;
  fetch?: boolean;
  payload?: any;
}

interface HookProps {
  path: string;
  options?: Options;
}

// GET API HOOK
export const useGetApi = <T>(path: string, options: Options = {}) => {
  const { isToaster = false, fetch, payload } = options;
  const [data, setData] = useState<ResponseDataModel<T> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null | any>(null);

  const getData = useCallback(
    async (newPath?: string) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await AxiosClient.get(newPath || path);
        if (response && response?.data) {
          setData(response?.data);
          if (response?.data?.message && isToaster) {
            toast.success(response?.data?.message, TOASTER_CONFIG);
          }
          return response;
        }
      } catch (error: any) {
        errorLog(error, setError, isToaster);
        return error;
      } finally {
        setIsLoading(false);
      }
    },
    [path, isToaster]
  );

  useEffect(() => {
    fetch && getData(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, getData]);

  return { data, isLoading, error, getData };
};

// POST API HOOK
export const usePostApi = <T>({ path, options = {} }: HookProps) => {
  const { isToaster = true, fetch, payload } = options;
  const [data, setData] = useState<ResponseDataModel<T> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [errorData, setErrorData] = useState<any | null>(null);

  const postData = useCallback(
    async (body?: any, newPath?: string) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await AxiosClient.post(newPath || path, body);
        if (response && response?.data) {
          setData(response?.data);
          if (response?.data?.message && isToaster) {
            toast.success(response?.data?.message, TOASTER_CONFIG);
          }
          return response;
        }
      } catch (error: any) {
        setErrorData(error?.response?.data);
        errorLog(error, setError, isToaster);
        return error;
      } finally {
        setIsLoading(false);
      }
    },
    [path, isToaster]
  );

  useEffect(() => {
    fetch && postData(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, postData]);

  return { data, isLoading, error, postData, errorData };
};

// Put API HOOK
export const usePutApi = <T>({ path, options = {} }: HookProps) => {
  const { isToaster = true, fetch, payload } = options;
  const [data, setData] = useState<ResponseDataModel<T> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [errorData, setErrorData] = useState<any | null>(null);

  const putData = useCallback(
    async (body?: any, newPath?: string) => {
      setIsLoading(true);
      setError(null);
      setData(null);

      try {
        const response = await AxiosClient.put(newPath || path, body);
        if (response && response?.data) {
          setData(response?.data);
          if (response?.data?.message && isToaster) {
            toast.success(response?.data?.message, TOASTER_CONFIG);
          }
          return response;
        }
      } catch (error: any) {
        setErrorData(error?.response?.data);
        errorLog(error, setError, isToaster);
        return error;
      } finally {
        setIsLoading(false);
      }
    },
    [path, isToaster]
  );

  useEffect(() => {
    fetch && putData(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, putData]);

  return { data, isLoading, error, putData, errorData };
};

// Get File Blob from URL
export const useGetBlob = ({ path, options = {} }: HookProps) => {
  const { isToaster = false } = options;
  const [data, setData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const getFile = useCallback(
    async (newPath: string) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await axios.get(newPath || path, {
          responseType: "blob",
        });
        if (response && response?.data) {
          setData(response?.data);
          return response;
        }
      } catch (error: any) {
        errorLog(error, setError, isToaster);
        return error;
      } finally {
        setIsLoading(false);
      }
    },
    [isToaster, path]
  );

  return { data, isLoading, error, getFile };
};

// generic error log
const errorLog = (error: any, setError: any, isToaster?: boolean) => {
  if (error && error?.response?.data) {
    setError(error?.response?.data?.message);
    if (isToaster) {
      toast.error(error?.response?.data?.message, TOASTER_CONFIG);
    }
  } else {
    setError("Unexpected error occurred... Please try again!");
  }
};
