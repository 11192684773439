import { useForm } from "react-hook-form";
import FormWrapper from "../../components/form-wrapper/FormWrapper";
import {
  EmailVerificationFormDataModel,
  ForgotPasswordFormDataModel,
} from "../../models/auth-form.model";
import { useEffect, useState } from "react";
import Button from "../../components/widgets/Button";
import InputField from "../../components/widgets/InputField";
import { usePostApi } from "../../services/use-api";
import API_CONSTANTS from "../../constants/api-constant";
import { emailPattern } from "../../constants/regex-pattern";
import { useNavigate, useSearchParams } from "react-router-dom";
import PasswordUpdatedPage from "../../components/password-updated/PasswordUpdated";
import RestrictSmallDeviceDialog from "../../components/restrict-small-device-dialog/RestrictSmallDeviceDialog";
import ForgotPasswordForbidden from "../../components/forgot-password-forbidden/ForgotPasswordForbidden";

const ForgotPassword = () => {
  const resetPasswordForm = useForm<ForgotPasswordFormDataModel>();
  const emailVerificationForm = useForm<EmailVerificationFormDataModel>();
  const [isMailSend, setIsMailSend] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [isPassUpdatedSuccessfully, setIsPassUpdatedSuccessfully] =
    useState(false);
  const [isPassUpdateForbidden, setIsPassUpdateForbidden] = useState(false);
  const {
    data: sendEmailResData,
    postData: sendEmail,
    isLoading: isMainSending,
  } = usePostApi({
    path: API_CONSTANTS.FORGOT_PASSWORD,
  });

  const {
    data: resetPassResData,
    postData: resetPassword,
    isLoading: isPasswordResetLoading,
  } = usePostApi({
    path: API_CONSTANTS.RESET_PASSWORD,
  });

  //   Send Verification Code Function
  const sendVerificationCodeOnMail = (data: EmailVerificationFormDataModel) => {
    sendEmail({
      email: data.email.trim(),
    });
  };

  //   Update Password Function
  const updatePassword = (data: ForgotPasswordFormDataModel) => {
    const email = emailVerificationForm.getValues("email");
    if (passwordWatch === confirmPasswordWatch) {
      resetPassword({
        email: email,
        password: data.newPassword,
        resetToken: data.code,
        confirmPassword: data.confirmNewPassword,
      });
    } else {
      resetPasswordForm.setError("confirmNewPassword", {
        message: "Password not matched",
      });
    }
  };

  useEffect(() => {
    emailVerificationForm.setValue("email", email ?? "");
  }, [email, emailVerificationForm]);

  //   Check Mail Send Response Data and change state
  useEffect(() => {
    if (sendEmailResData && sendEmailResData.success) {
      setIsMailSend(true);
    } else if (sendEmailResData && sendEmailResData.status_code === 403) {
      setIsPassUpdateForbidden(true);
    }
  }, [sendEmailResData]);

  //  Change PasswordUpdate State
  useEffect(() => {
    if (resetPassResData && resetPassResData.success) {
      setIsPassUpdatedSuccessfully(true);
    }
  }, [navigate, resetPassResData]);

  const confirmPasswordWatch = resetPasswordForm.watch("confirmNewPassword");
  const passwordWatch = resetPasswordForm.watch("newPassword");

  useEffect(() => {
    const password = resetPasswordForm.getValues("newPassword");
    if (password?.length !== 0 && confirmPasswordWatch !== password) {
      resetPasswordForm.setError("confirmNewPassword", {
        message: "Password not matched",
      });
    } else {
      resetPasswordForm.setError("confirmNewPassword", {
        message: "",
      });
    }
  }, [confirmPasswordWatch, resetPasswordForm]);

  useEffect(() => {
    if (passwordWatch?.length === 0) {
      resetPasswordForm.setError("confirmNewPassword", {
        message: "",
      });
    }
  }, [resetPasswordForm, passwordWatch?.length]);

  return (
    <>
      <div className="hidden lg:block">
        {isPassUpdatedSuccessfully ? (
          <PasswordUpdatedPage />
        ) : isPassUpdateForbidden ? (
          <ForgotPasswordForbidden data={sendEmailResData} />
        ) : (
          <FormWrapper formHeaderLabel="Forgot Password">
            {!isMailSend ? (
              //   {/* Email Verification Form */}
              <form
                onSubmit={emailVerificationForm.handleSubmit(
                  sendVerificationCodeOnMail
                )}
              >
                <div>
                  <InputField
                    form={emailVerificationForm}
                    name="email"
                    label="Email"
                    validation={{
                      required: "Required",
                      pattern: emailPattern,
                    }}
                  />
                  <Button
                    isLoading={isMainSending}
                    className="w-[210px]"
                    label="Send Verification Code"
                    type="submit"
                  />{" "}
                </div>
              </form>
            ) : (
              // Password Reset Form
              <form onSubmit={resetPasswordForm.handleSubmit(updatePassword)}>
                <div>
                  <InputField
                    form={emailVerificationForm}
                    name="email"
                    label="Email"
                    readonly={isMailSend}
                    validation={{
                      required: "Required",
                    }}
                  />
                  <InputField
                    form={resetPasswordForm}
                    name="newPassword"
                    label="Password"
                    type="password"
                    validation={{
                      required: "Required",
                    }}
                  />
                  <InputField
                    form={resetPasswordForm}
                    name="confirmNewPassword"
                    label="Confirm New Password"
                    type="password"
                    validation={{
                      required: "Required",
                    }}
                  />
                  <InputField
                    form={resetPasswordForm}
                    name="code"
                    label="Verification Code"
                    type="password"
                    validation={{
                      required: "Required",
                    }}
                  />
                  <Button
                    className="w-[160px]"
                    isLoading={isPasswordResetLoading}
                    label="Update Password"
                    type="submit"
                  />{" "}
                </div>
              </form>
            )}
          </FormWrapper>
        )}
      </div>
      <div className="block lg:hidden">
        <RestrictSmallDeviceDialog />
      </div>
    </>
  );
};
export default ForgotPassword;
