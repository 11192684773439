import { cn } from "../../utils/common-utils";

interface ButtonProps {
  label?: string;
  className?: string;
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
  disable?: boolean;
  isLoading?: boolean;
  variant?: "primary" | "secondary";
  children?: React.ReactNode;
}

const Button = ({
  label,
  className,
  onClick,
  type = "button",
  disable = false,
  variant = "primary",
  isLoading = false,
  children,
}: ButtonProps) => {
  const secondaryVariantCalss = "bg-transprent text-white";

  return (
    <button
      type={type}
      className={cn(
        "bg-[#3AC7A6] border-white border-[1px] border-opacity-25 rounded-[900px] w-[100px] h-[47px] hover:opacity-45 mt-10 text-[#16191D]",
        className,
        variant === "secondary" ? secondaryVariantCalss : "",
        disable || isLoading
          ? "cursor-not-allowed w-[150px] opacity-45 text-[#16191D] "
          : ""
      )}
      onClick={disable || isLoading ? undefined : onClick}
      disabled={disable || isLoading}
    >
      {isLoading ? (
        <div className="flex items-center justify-center text-[#16191D]">
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-[#16191D]"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25 text-white"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75 text-[#16191D]"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Loading...
        </div>
      ) : (
        <>{children ?? label ?? "Submit"}</>
      )}
    </button>
  );
};
export default Button;
