import { useNavigate } from "react-router-dom";
import AppTable from "../../components/app-table/AppTable";
import Button from "../../components/widgets/Button";
import { ChurchTableDataModel } from "../../models/platform-admin.model";
import { ROUTES } from "../../constants/routes-constants";
import { useGetApi } from "../../services/use-api";
import API_CONSTANTS from "../../constants/api-constant";
import { useEffect, useState } from "react";
import FormWrapperLoading from "../../components/form-wrapper/FormWrapperLoading";

const PlatformAdminHome = () => {
  const {
    data: churchesResData,
    getData: getChurches,
    isLoading: isChurchesDataLoading,
  } = useGetApi<ChurchTableDataModel[]>(API_CONSTANTS.GET_ALL_CHURCH);

  const [churches, setChurches] = useState<ChurchTableDataModel[]>([]);

  const navigate = useNavigate();

  const gotoAddNewChurch = () => {
    navigate(ROUTES.ADD_NEW_CHURCH);
  };

  useEffect(() => {
    getChurches();
  }, [getChurches]);

  useEffect(() => {
    if (churchesResData && churchesResData.success) {
      setChurches(churchesResData.body ?? []);
    }
  }, [churchesResData]);

  return (
    <div>
      {isChurchesDataLoading ? (
        <FormWrapperLoading />
      ) : (
        <div className="m-10">
          <div className="mb-6">
            <label className="text-lg">Admin</label>
            <div className="flex justify-between items-center">
              <h1 className="text-[40px]">First 30 Churches</h1>
              <Button
                className="w-[180px] h-[50px]"
                label="Add New Church"
                onClick={gotoAddNewChurch}
              />
            </div>
          </div>
          {churches.length !== 0 ? (
            <AppTable data={churches} />
          ) : (
            <div className="flex items-center justify-center h-[60vh]">
              <label className="text-xl">No Churches Found</label>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PlatformAdminHome;
