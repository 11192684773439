import AppDialog from "../app-dialog/AppDialog";

const RestrictSmallDeviceDialog = () => {
  return (
    <div>
      <div className="absolute top-[20%] left-[38%] md:top-[25%] md:left-[42%] z-[500]">
        <img
          className="md:h-[110px] md:w-[110px]"
          alt="Logo"
          src="/assets/logo.png"
        />
      </div>

      <AppDialog
        isOpen={true}
        description="For the best experience, please view this site on a desktop or
      laptop computer."
        isLoading={false}
        onClose={() => {}}
        hideActionBtn={true}
        hideCloseBtn={true}
        onConfirm={() => {}}
        title="View First 30 days site on Desktop"
      />
    </div>
  );
};

export default RestrictSmallDeviceDialog;
