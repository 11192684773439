import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes-constants";
import { ChurchTableDataModel } from "../../models/platform-admin.model";

interface AppTableProps {
  data: ChurchTableDataModel[];
}

const AppTable = ({ data }: AppTableProps) => {
  const navigate = useNavigate();

  const goToEditChurch = (id: string) => {
    navigate(`${ROUTES.EDIT_CHURCH}?editId=${id}`);
  };

  return (
    <table className="w-full">
      <thead>
        <tr className="text-left border-b">
          <th className="pb-2">Church Name</th>
          <th className="pb-2">Paid</th>
          <th className="pb-2">Admin</th>
        </tr>
      </thead>
      <tbody>
        {data.map((church, index) => (
          <tr key={index} className="border-b">
            <td className="py-3">{church.name}</td>
            <td className="py-3">{church.isSubscribed ? "Yes" : "No"}</td>
            <td className="py-3">
              <button
                onClick={goToEditChurch.bind(this, church.id)}
                className="font-bold underline"
              >
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default AppTable;
